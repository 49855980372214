import { DownOutlined, RightOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Card,
  Col,
  Empty,
  Form,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import PartnerSelect from "../components/Partners/PartnerSelect";
import {debounce} from "lodash";

const cleanObject = (obj) => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "null" ||
      obj[propName] === "undefined" ||
      obj[propName] === "" ||
      obj[propName] === false
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

/**
 * Page with all buildings
 * @component
 * @alias BuildingsIndex
 * @returns <Card /> with content
 */
const BuildingsIndex = (props) => {
  const { t } = useTranslation();

  const [filterForm] = Form.useForm();

  const areaScope = useSelector(getAreaScope);
  const selectedCity = Number(areaScope.areaScope);
  const [params, setParams] = useState(new URLSearchParams(props.location.search));

  // States
  const [buildings, setBuildings] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isDataLoading, setDataLoading] = useState(false);
  const [autocompleteStreets, setAutocompleteStreets] = useState([]);
  const [autocompleteRegNums, setAutocompleteRegNums] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  let defaultFilters = {
    city_branch_id:
      selectedCity !== 0
        ? Number(selectedCity)
        : params.get("city_branch_id")
          ? Number(params.get("city_branch_id"))
          : null,
    street: String(params.get("street")) || "",

    // Autocomplete values
    district: params.get("district") ? String(params.get("district")) : "",
    postal_code: params.get("postal_code") ? String(params.get("postal_code")) : "",
    registration_number: params.get("registration_number") ? String(params.get("registration_number")) : "",
    client_name: params.get("client_name") ? String(params.get("client_name")) : "",

    contract_not_signed: params.get("contract_not_signed") === "true" ? true : false,
    contract_terminated: params.get("status") === 'contract_terminated',
    is_waiting: params.get("status") === 'is_waiting',
    is_active: params.get("status") === 'is_active',
    employee_id: Number(params.get("employee_id")) || null,
    page: Number(params.get("page")) || null,
    partner_id: Number(params.get("partner_id")) || null,
  };

  // Values for filtering
  const [filters, setFilters] = useState(defaultFilters);

  // Colums of table
  const buildingTableColumns = [
    {
      title: t("buildings.registrationNumber"),
      dataIndex: "registration_number",
      key: "building_id",
      width: "15%",
      sorter: (a, b) => a.registration_number - b.registration_number,
      render: (text, row, index) => {
        return (
          <React.Fragment key={index}>
            <Tooltip placement="topLeft" title={row.registration_number}>
              <Link to={`/buildings/${row.id}`} style={{ fontWeight: 500 }}>
                {row?.registration_number ? (
                  row?.ending_date ? (
                    <del>{row?.registration_number}</del>
                  ) : (
                    row?.registration_number
                  )
                ) : (
                  <Tag color={"red"}>{t("buildings.withoutRegNum")}</Tag>
                )}
              </Link>
            </Tooltip>
          </React.Fragment>
        );
      },
    },
    {
      title: t("buildings.reference"),
      dataIndex: "reference",
      key: "reference",
      width: "25%",
      sorter: (a, b) => a.reference.localeCompare(b.reference),
      render: (text, row, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {row?.ending_date ? (
              <del>
                <Link to={`/buildings/${row.id}`} style={{ fontWeight: 500 }}>
                  {text}
                </Link>
              </del>
            ) : (
              <Link to={`/buildings/${row.id}`} style={{ fontWeight: 500 }}>
                {text}
              </Link>
            )}
          </Tooltip>
        );
      },
    },
    {
      title: t("buildings.clientName"),
      dataIndex: "client_name",
      key: "client_name",
      width: "30%",
      sorter: (a, b) => a.client_name.localeCompare(b.client.name),
      render: (text, row, index) => {
        return (
          <Tooltip placement="topLeft" title={row?.client_name}>
            <Link to={"/clients/" + row.client_id} style={{ borderBottom: "1px dotted" }}>
              {row?.client_name}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      width: "10%",
      title: t("buildings.city"),
      dataIndex: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      width: "15%",
      title: t("buildings.district"),
      dataIndex: "district",
      sorter: (a, b) => a.district.localeCompare(b.district),
    },
    {
      width: "10%",
      title: t("buildings.postalCode"),
      dataIndex: "postal_code",
      sorter: (a, b) => a.postal_code.localeCompare(b.postal_code),
    },
  ];

  useEffect(() => {
    let isMounted = true;

    axios
      .all([coreApi.get("employees/all")])
      .then(
        axios.spread((employees) => {
          if (isMounted) {
            setEmployees([...employees.data]);
          }
        })
      )
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setLoading(false));

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (!filters.city_branch_id) return;
    if (filters.city_branch_id === 0) return;

    filterForm.setFieldsValue({ ...cleanObject(filters) });
    setDataLoading(true);

    coreApi
      .get("/buildings", {
        params: {
          ...cleanObject(filters),
        },
      })
      .then((res) => {
        let { data, ...pagination } = res.data;
        setBuildings([...data]);

        props.history.push({
          pathname: "/buildings",
          search: "?" + new URLSearchParams({ ...cleanObject(filters) }).toString(),
        });

        setPaginationDetails({ ...pagination });
      })
      .catch((err) => notification.error({ message: err.response.data.message }))
      .finally(() => setDataLoading(false));
  }, [filters]);

  const handleChange = (values) => {
    let parameters = {};
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.forEach((value, key) => {
      parameters[key] = value;
    });

    for (let key in values) {
      if (values.hasOwnProperty(key)) {
        parameters[key] = values[key];
      }
    }

    setFilters({
      ...filters,
      ...parameters
    });
  };



  const handleRadioChange = (e) => {
    const updatedValue = e.target.value === selectedValue ? null : e.target.value;
    if (selectedValue !== null) delete filters[selectedValue];
    setSelectedValue(updatedValue);
    setFilters({
      ...filters,
      [updatedValue]: true,
    });
  };

  const handleRadioClick = (value) => {
    if (selectedValue === value) {
      delete filters[value];
      setSelectedValue(null);
      setFilters({
        ...filters,
        [value]: false,
      });
    }
  };

  const debouncedHandleChange = useCallback(
    debounce((values) => {
      handleChange(values);
    }, 800),
    []
  );

  const handleValuesChange = (values) => {
    if (values.street !== undefined || values.registration_number !== undefined) {
      debouncedHandleChange(values);
    } else {
      handleChange(values);
    }
  };

  useEffect(() => {
    if (selectedCity === 0) return;

    filterForm.resetFields();
    setFilters({ city_branch_id: selectedCity });

    axios
      .all([
        coreApi.get("/buildings/streets", {
          params: { city_branch_id: selectedCity },
        }),
        coreApi.get("/buildings/registration-numbers", {
          params: { city_branch_id: selectedCity },
        }),
      ])
      .then(
        axios.spread((streets, regNums) => {
          setAutocompleteStreets(
            streets.data.map((item) => ({
              value: item,
            }))
          );

          setAutocompleteRegNums(
            regNums.data.map((item) => ({
              value: item.registration_number,
            }))
          );
        })
      )
      .catch((err) =>
        notification.error({
          message: err.response.data.message,
        })
      );
  }, [selectedCity]);

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={24} gutter={[16, 16]}>
          <Card>
            <Col span={24} gutter={[16, 16]}>
              <Form
                className="filterForm"
                form={filterForm}
                onValuesChange={(values) => {
                  handleValuesChange(values)
                }}
                onFinish={(values) => {
                  setFilters({
                    ...values,
                    page: 1,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") filterForm.submit();
                }}
              >
                <Row gutter={[8, 0]}>
                  <Col span={4}>
                    <Form.Item name="street">
                      <AutoComplete
                        allowClear={true}
                        backfill={true}
                        notFoundContent={
                          !autocompleteRegNums.length > 0 && (
                            <Spin
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            />
                          )
                        }
                        placeholder={t("buildingUnits.street")}
                        options={autocompleteStreets}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      ></AutoComplete>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name="registration_number" initialValue={filters?.registration_number}>
                      <AutoComplete
                        allowClear={true}
                        backfill={true}
                        notFoundContent={
                          !autocompleteRegNums.length > 0 && (
                            <Spin
                              style={{
                                width: "100%",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            />
                          )
                        }
                        placeholder={t("buildings.registrationNumber")}
                        options={autocompleteRegNums}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                      ></AutoComplete>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <PartnerSelect
                      name={"partner_id"}
                      validationMessage={t("cleanings.validation.partners")}
                      initialValue={filters?.partner_id || null}
                      required={false}
                      showSearch={true}
                    />
                  </Col>
                  <Col span={4}>
                    <Form.Item name="employee_id" initialValue={filters?.employee_id}>
                      <Select
                        placeholder={t("employees.employee")}
                        allowClear={true}
                        autoClearSearchValue={true}
                        showSearch={true}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      >
                        {employees.map((employee) => {
                          return (
                            <Select.Option value={employee.id} key={employee.id}>
                              {employee.first_name + " " + employee.last_name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      <Radio.Group onChange={(e) => handleRadioChange(e)} value={selectedValue} style={{display: 'flex', textAlign: 'center', marginLeft: '50px'}}>
                        <Col span={8}>
                          <Radio value="contract_terminated" onClick={() => handleRadioClick("contract_terminated")}>{t("buildings.contractEnded")}</Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value="is_waiting" onClick={() => handleRadioClick("is_waiting")}>{t("buildings.isWaiting")}</Radio>
                        </Col>
                        <Col span={8}>
                          <Radio value="is_active" onClick={() => handleRadioClick('is_active')}>{t("buildings.isActive")}</Radio>
                        </Col>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Row>
              <Col span={24}>
                <Table
                  pagination={{
                    current: paginationDetails?.current_page,
                    pageSize: paginationDetails?.per_page,
                    total: paginationDetails?.total,
                    showTotal: (total, range) => (
                      <p>
                        {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                        {" " + t("common.paginationPartTwo") + " "}
                        <strong>{total}</strong> {t("common.paginationPartThree")}
                      </p>
                    ),
                  }}
                  onChange={(e) => {
                    setFilters({ ...filters, ...e, page: e.current });
                    console.log(e);
                  }}
                  locale={{
                    emptyText: (
                      <span>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={filters.city_branch_id ? t("common.noDataFound") : t("common.noData")}
                        />
                      </span>
                    ),
                  }}
                  size="small"
                  loading={isDataLoading}
                  dataSource={buildings}
                  columns={buildingTableColumns}
                  rowKey={"id"}
                  expandable={{
                    expandedRowRender: (buildings) => {
                      return (
                        <p style={{ margin: 0 }}>
                          {buildings.building_units.map((buildingUnit) => {
                            return (
                              <React.Fragment key={buildingUnit?.id}>
                                <Link to={"/buildings/" + buildingUnit.building_id + "/unit/" + buildingUnit?.id}>
                                  {buildingUnit.street + " " + buildingUnit.house_number}
                                </Link>
                                <br />
                              </React.Fragment>
                            );
                          })}
                        </p>
                      );
                    },
                    expandIcon: ({ expanded, onExpand, record, expandable }) =>
                      expandable &&
                      (expanded ? (
                        <Tooltip placement="top" title={t("buildings.hideBuildingUnits")}>
                          <DownOutlined onClick={(e) => onExpand(record, e)} style={{ transition: "linear 1s 0.2s" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="top" title={t("buildings.showBuildingUnits")}>
                          <RightOutlined
                            onClick={(e) => onExpand(record, e)}
                            style={{ transition: "linear 1s 0.2s" }}
                          />
                        </Tooltip>
                      )),
                    rowExpandable: (buildings) => buildings?.building_units?.length > 0,
                  }}
                ></Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingsIndex;