import { Button, Col, DatePicker, Row, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { coreApi } from "../../api/calls";

const Dac7YearlyExport = () => {
  const { t } = useTranslation();
  const [year, setYear] = useState(moment().subtract(1, "year").year());
  const [isLoading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    coreApi
      .get(`/dac7/export/yearly-report/${year}`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        fileDownload(data, `dac7-yearly-export-${year}.csv`);
        notification.success({ message: t("exports.success") });
      })
      .catch((err) =>
        notification.error({
          message: err.response?.data?.message || t("exports.error"),
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Row gutter={16} align="middle">
      <Col span={4}>
        <DatePicker
          picker="year"
          value={moment(year, "YYYY")}
          onChange={(date) => setYear(date ? date.year() : year)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={4}>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          loading={isLoading}
          onClick={handleDownload}
        >
          {t("exports.export")}
        </Button>
      </Col>
    </Row>
  );
};

export default Dac7YearlyExport;