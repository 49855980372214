import {
  AutoComplete,
  Card,
  Col,
  Form,
  Row,
  Spin,
  Table,
  Tag,
  notification,
  DatePicker,
  Input,
  Tooltip,
  Space,
  Button,
  Select,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAreaScope } from "../actions";
import { coreApi } from "../api/calls";
import debounce from "lodash.debounce";
import PartnerLink from "../components/Partners/PartnerLink";
import PartnerSelect from "../components/Partners/PartnerSelect";
import axios from "axios";
import ManagerSelect from "../components/ManagerSelect";
import EmployeeLink from "../components/Employees/EmployeeLink";
import BuildingUnitLink from "../components/Buildings/BuildingUnitLink";
import { DownloadOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import FrequencySelect from "../components/custom/Form/FrequencySelect";
import RruleDaySelect from "../components/Scheduler/RruleDaySelect";

/**
 * Page with all buildings units.
 *
 * @component
 * @alias BuildingUnitsIndex
 * @returns <Fragment /> with content
 */
const BuildingUnitsIndex = (props) => {
  // Translation constant
  const { t } = useTranslation();

  // States
  const [loading, setLoading] = useState(true);
  const [buildingUnits, setBuildingUnits] = useState([]);
  const [pagination, setPagination] = useState({});
  const [filters, setFilters] = useState({pageSize: 10, status: "active"});
  const [sorter, setSorter] = useState({});
  const areaScope = useSelector(getAreaScope);
  const selectedCity = Number(areaScope.areaScope);
  const [autocompleteDistricts, setAutocompleteDistricts] = useState([]);
  const [autocompleteRegNums, setAutocompleteRegNums] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [selectedFrequencyText, setSelectedFrequencyText] = useState('');

  const checkFrequencyMatch = (rruleBlueprint, selectedFrequency) => {
    if (!rruleBlueprint || !selectedFrequency) return false;

    const bydayPos = rruleBlueprint.indexOf('BYDAY=');
    let truncatedRrule;
    let suffixLength;

    if (bydayPos !== -1) {
      truncatedRrule = rruleBlueprint.slice(0, bydayPos + 6);
      suffixLength = rruleBlueprint.slice(bydayPos + 6).length;
    } else {
      truncatedRrule = rruleBlueprint.slice(0, -1);
      suffixLength = 1;
    }

    const regex = new RegExp('^' + truncatedRrule + '.{' + suffixLength + '}$');
    return regex.test(selectedFrequency);
  };
  // Columns of the table
  const columns = [
    {
      title: t("expansion.managerName"),
      key: "employee_first_name",
      dataIndex: "service_rrules",
      sorter: true,
      render: (rrules) => {
        let employee = rrules?.[rrules?.length - 1]?.partner?.user?.employee;

        return employee ? (<EmployeeLink employee={employee} />) : ('');
      },
    },
    {
      title: t("buildings.registrationNumber"),
      key: "building_registration_number",
      dataIndex: "building",
      sorter: true,
      width: "110px",
      render: (building) => (
        <Tooltip title={building?.registration_number}>
          <Link to={"/buildings/" + building?.id} target={"_blank"} style={{ borderBottom: "1px dotted" }}>
            <span>{building?.registration_number}</span>
          </Link>
        </Tooltip>
      ),
    },
    {
      title: t("common.address"),
      key: "street",
      sorter: true,
      width: '220px',
      render: (buildingUnit) => <BuildingUnitLink buildingUnit={buildingUnit} />,
    },
    {
      title: t("common.city"),
      key: "city",
      sorter: (a, b) => a.building?.city_branch?.name?.localeCompare(b.building?.city_branch?.name),
      render: (data) => data.building?.city_branch?.name,
    },
    {
      title: t("common.district"),
      key: "district",
      dataIndex: "district",
      sorter: true,
    },
    {
      title: t("cleanings.partner"),
      key: "partner_first_name",
      dataIndex: "service_rrules",
      sorter: true,
      render: (rrules) => <PartnerLink partner={rrules?.[rrules?.length - 1]?.partner} />,
    },
    {
      title: t("schedules.pricePerUnit"),
      key: "price_per_unit",
      dataIndex: "building",
      sorter: true,
      render: (building) => {
        return building?.price_per_unit ? new Intl.NumberFormat("cs-CZ", {
          style: "currency",
          currency: building?.city_branch?.country_branch?.iso_4217_currency_code || "CZK",
        }).format(building?.price_per_unit) : 0;
      },
    },
    {
      title: t("schedules.cleaningDays"),
      key: "cleaning_days_sort",
      dataIndex: "cleaning_days",
      sorter: true,
      render: (days) => {
        return days
          .map((day) => {
            if (day?.includes(",")) {
              let moreDays = [];

              day?.split(",").forEach((day) => {
                moreDays.push(t("rrules.byweekday." + day));
              });

              return moreDays;
            } else {
              return t("rrules.byweekday." + day);
            }
          })
          .join(", ");
      },
    },
    {
      title: t("schedules.frequancy"),
      key: "service_rrules_sort_weight",
      dataIndex: "service_rrules",
      sorter: true,
      render: (rrules) => {
        if(!rrules) return;
        let index = 0;
        let total = rrules
            .sort((a, b) => {
              return a.cleaning_service_group?.sort_weight - b.cleaning_service_group?.sort_weight;
            })
            ?.filter((rrule) => {
              return rrule?.rrule?.includes("WEEKLY");
            })

        if (selectedFrequency !== '' && selectedFrequency !== undefined && total.length) {
          /* search by name */
          let key = Object.keys(total).find(key => {
            const name = total[key].cleaning_service_group?.name;
            return name && (name.includes(selectedFrequencyText) || selectedFrequencyText.includes(name) || selectedFrequencyText == name);
          });
          if (key) index = key;
          else {
            /* search by rule */
            let key = Object.keys(total).find(key => {
              const rule = total[key].cleaning_service_group?.rrule_blueprint;
              const ruleExt = 'RRULE:' + selectedFrequency;
              return rule && (selectedFrequency.includes(rule) || selectedFrequency == rule || ruleExt.includes(rule) || ruleExt == rule || checkFrequencyMatch(rule, selectedFrequency));
            });
            if (key) index = key;
            else {
              /* search by rule variations */
              key = Object.keys(total).find(key => {
                const rruleBlueprint = total[key].cleaning_service_group?.rrule_blueprint;
                const name = rruleBlueprint ? rruleBlueprint.slice(0, -2) : false;
                return name && selectedFrequency.includes(name);
              });
              if (key) index = key;
              else return selectedFrequencyText
            }
          }
        }
        return total[index]?.cleaning_service_group?.name;
      },
      ellipsis: true,
      width: "10%",
    },
  ];

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (paginating, filter, sorting) => {
    const newFilters = {
      ...filters,
      ...filter,
      current: paginating.current,
      pageSize: paginating.pageSize
    };
    setFilters(newFilters);
    setSorter(sorting);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return {
        disabled: !record?.cleaning_service_groups?.length,
        title: t("schedules.frequency") + ': ' + record?.cleaning_service_groups?.map(row => row?.name).join(', ')
      };
    },
  };

  const handleDownloadSelectedPDFScheudle = async () => {
    if (selectedRowKeys.length > 0) {
      setLoading(true);

      const downloadPromises = selectedRowKeys.map(async (key) => {
        const buildingUnit = buildingUnits.find(unit => unit.id === key);

        if (buildingUnit) {
          try {
            const response = await coreApi.get("/cleaning-schedule", {
              responseType: "blob",
              params: {
                building_unit_id: buildingUnit.id,
                cleaning_date_start: moment().format("YYYY-MM-DD"),
              },
            });

            fileDownload(
              response.data,
              `rozpis-${buildingUnit.street.toLowerCase()}-${buildingUnit.house_number}.pdf`
            );
          } catch (err) {
            notification.error({
              message: t("schedules.downloadError"),
              description: err?.response?.data?.message || t("schedules.downloadErrorDescription")
            });
          }
        }
      });

      await Promise.all(downloadPromises).then(() => {
        setLoading(false);
      });
    }
  };

  /**
   * Fetch data from API
   */
  const fetchData = useCallback(() => {
    if (fetchInProgress.current) return;
    fetchInProgress.current = true;
    setLoading(true);

    coreApi
      .get("/building-units", {
        params: {
          serviceRrules: filters?.service_rrules || null
          /*? filters?.service_rrules?.includes("FREQ=WEEKLY;INTERVAL=2;")
            ? "FREQ=WEEKLY;INTERVAL=2;"
            : "FREQ=WEEKLY;BYDAY="
          : null*/,
          cleaningDays: filters?.cleaning_days || null,
          frequency: filters?.frequency || null,
          city_branch_id: filters?.city_branch_id || null,
          sorterOrder: sorter?.order || null,
          sorterKey: sorter?.columnKey || null,
          cleaningsStartFrom: filters?.cleaningsStartFrom
            ? moment(filters?.cleaningsStartFrom).format("YYYY-MM-DD")
            : null,
          endingDate: filters?.endingDate ? moment(filters?.endingDate).format("YYYY-MM-DD") : null,
          registrationNumber: filters?.registrationNumber,
          page_size: filters?.pageSize || 10,
          page: filters?.current,
          district: filters?.district,
          dateConfirmed: filters?.dateConfirmed ? moment(filters?.dateConfirmed).format("YYYY-MM-DD") : null,
          contractNotSigned: filters?.contractNotSigned
            ? moment(filters?.contractNotSigned).format("YYYY-MM-DD")
            : null,
          status: filters?.status || null,
          partner_id: filters?.partner_id,
          manager_id: filters?.manager_id,
          street: filters?.street,
        },
      })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setBuildingUnits(data);
        setPagination(pagination);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
        fetchInProgress.current = false;
      });
  }, [filters, sorter]);

  const fetchInProgress = useRef(false);

  useEffect(() => {
    fetchData();
  }, [filters, sorter, fetchData]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      city_branch_id: selectedCity !== 0 ? Number(selectedCity) : null
    }));

    if (selectedCity === 0) return;

    axios
      .all([
        coreApi.get("/buildings/districts", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
        coreApi.get("/buildings/registration-numbers", {
          params: { city_branch_id: filters.city_branch_id || selectedCity },
        }),
      ])
      .then(
        axios.spread((districts, regNums) => {
          setAutocompleteDistricts(
            districts.data.map((item) => ({
              value: item.district,
            }))
          );

          setAutocompleteRegNums(
            regNums.data.map((item) => ({
              value: item.registration_number,
            }))
          );
        })
      )
      .catch((err) =>
        notification.error({
          message: err.response.data.message,
        })
      );
  }, [selectedCity]);

  /**
   * Handle street onchange filter with lodash
   */
  const handleStreetFilter = debounce((street) => {
    setFilters({ ...filters, street: street });
  }, 600);

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Card>
            <Row gutter={[8, 0]}>
              <Col span={4}>
                <Form.Item name="street" initialValue={filters?.street}>
                  <Input
                    debounce={true}
                    allowClear={true}
                    placeholder={t("common.address")}
                    onChange={(e) => handleStreetFilter(e?.target?.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="district" initialValue={filters?.district}>
                  <AutoComplete
                    allowClear={true}
                    autoClearSearchValue={true}
                    dropdownMatchSelectWidth={false}
                    notFoundContent={
                      !autocompleteDistricts.length > 0 && (
                        <Spin
                          style={{
                            width: "100%",
                            justifyContent: "center",
                            justifyItems: "center",
                          }}
                        />
                      )
                    }
                    onSearch={(value) => autocompleteDistricts?.filter((item) => item.value.includes(value))}
                    placeholder={t("common.district")}
                    options={autocompleteDistricts}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(e) => setFilters({ ...filters, district: e })}
                  ></AutoComplete>
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item name="registration_number" initialValue={filters?.registration_number}>
                  <AutoComplete
                    allowClear={true}
                    backfill={true}
                    notFoundContent={
                      !autocompleteRegNums.length > 0 && (
                        <Spin
                          style={{
                            width: "100%",
                            justifyContent: "center",
                            justifyItems: "center",
                          }}
                        />
                      )
                    }
                    placeholder={t("buildings.registrationNumber")}
                    options={autocompleteRegNums}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(e) => setFilters({ ...filters, registrationNumber: e })}
                  ></AutoComplete>
                </Form.Item>
              </Col>

              <Col span={4}>
                <PartnerSelect
                  name={"partner_id"}
                  validationMessage={t("cleanings.validation.partners")}
                  required={false}
                  showSearch={true}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      partner_id: e?.id,
                    });
                  }}
                />
              </Col>

              <Col span={4}>
                <ManagerSelect
                  clearable={true}
                  name={"manager_id"}
                  required={false}
                  showSearch={true}
                  sendOnchange={(id) => {
                    setFilters({
                      ...filters,
                      manager_id: id,
                    });
                  }}
                />
              </Col>

              <Col span={4}>
                <RruleDaySelect
                    placeholder={t("schedules.cleaningDay")}
                    name={"cleaning_days"}
                    clearable={true}
                    postChange={(e) => {
                      setFilters({
                        ...filters,
                        cleaning_days: e,
                      });
                    }}
                />
              </Col>

              <Col span={4}>
                <Form.Item>
                  <FrequencySelect
                      name={"frequency"}
                      initialValue={filters?.frequency || null}
                      required={false}
                      showSearch={true}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          service_rrules: e ? e.rrule_blueprint : null,
                        });
                        setSelectedFrequency(e ? e.rrule_blueprint : '')
                        setSelectedFrequencyText(e ? e.name : '')
                      }}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="status" initialValue={filters.status}>
                  <Select
                    allowClear
                    defaultValue={filters.status}
                    placeholder={t("buildingUnits.status")}
                    onChange={(value) => setFilters((prevFilters) => ({ ...prevFilters, status: value || null }))}
                  >
                    <Select.Option value="active">{t("buildingUnits.statusActive")}</Select.Option>
                    <Select.Option value="inactive">{t("buildingUnits.statusInactive")}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={5}>
              </Col>
              <Col span={10}>
                <Space style={{ marginBottom: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type={"primary"}
                    icon={<DownloadOutlined />}
                    onClick={handleDownloadSelectedPDFScheudle}
                    loading={loading}
                    disabled={selectedRowKeys.length <= 0}
                  >
                    {t("schedules.downloadPDFSchedule")}
                  </Button>
                </Space>
              </Col>
            </Row>
            <Table
              size="small"
              loading={loading}
              rowKey={"id"}
              dataSource={buildingUnits}
              columns={columns}
              rowSelection={rowSelection}
              pagination={{
                ...pagination,
                pageSize: filters?.pageSize,
                showSizeChanger: true,
                showTotal: (total, range) => (
                  <p>
                    {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
                    {" " + t("common.paginationPartTwo") + " "}
                    <strong>{total}</strong> {t("common.paginationPartThree")}
                  </p>
                ),
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BuildingUnitsIndex;
