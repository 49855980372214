import { Button, Col, DatePicker, Form, InputNumber, notification, Row, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import PartnerSelect from "../Partners/PartnerSelect";
import BiweeklyTypeSelect from "./BiweeklyTypeSelect";
import moment from "moment";

const AddSchedule = ({ buildingUnitId, schedules, refreshScheduleList }) => {
  const { t } = useTranslation();

  //  const [isLoading, setLoading] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const [partners, setPartners] = useState([]);
  const days = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];

  const [addForm] = Form.useForm();
  const [isBiweeklyRequired, setIsBiweeklyRequired] = useState(false);
  const [biweeklyType, setBiweeklyType] = useState(null);
  const [rules, setRules] = useState([]);
  const [dateFrom, setDateFrom] = useState(moment());

  useEffect(() => {
    //setLoading(true);
    coreApi
      .get("/partners")
      .then((res) => setPartners(Array.isArray(res.data) ? [...res.data] : []))
      .catch((err) => console.log(err));
    //.finally(() => setLoading(false));

    coreApi
      .get("buildings/units/groups/" + buildingUnitId)
      .then((response) => setRules(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {

    let isBiweekly = false;
    let newBiweeklyType = undefined;

    schedules?.forEach(schedule => {
      const weekNumber = schedule?.date_from ? moment(schedule.date_from).isoWeek() : 0;
      schedule?.rules.forEach(rule => {
        const { rrule } = rule;
        if (
          rrule.includes("FREQ=WEEKLY") &&
          rrule.includes("INTERVAL=2") &&
          !schedules.some(scd =>
            scd?.rules.some(item =>
              item.rrule.includes("FREQ=WEEKLY") &&
              (item.rrule.includes("INTERVAL=1") || !item.rrule.includes("INTERVAL="))
            )
          )
        ) {
          isBiweekly = true;

          if (["odd", "even"].includes(rule?.parity)) {
            setBiweeklyType(rule?.parity);
          } else if (weekNumber > 0) {
            newBiweeklyType = weekNumber % 2 === 0 ? "even" : "odd";
            setBiweeklyType(newBiweeklyType);
          }
        }
      });
    });

    if (/*!schedules.length && */rules.length) {
      const weekNumber = moment(dateFrom).isoWeek();
      rules.forEach(rule => {
        const rrule = rule.rrule_blueprint;
        if (
          rrule.includes("FREQ=WEEKLY") &&
          rrule.includes("INTERVAL=2") &&
          !rules.some(item =>
            item.rrule_blueprint.includes("FREQ=WEEKLY") &&
            (item.rrule_blueprint.includes("INTERVAL=1") || !item.rrule_blueprint.includes("INTERVAL="))
          )
        ) {
          isBiweekly = true;

          if (["odd", "even"].includes(rule?.parity)) {
            setBiweeklyType(rule?.parity);
          } else if (weekNumber > 0) {
            newBiweeklyType = weekNumber % 2 === 0 ? "even" : "odd";
            setBiweeklyType(newBiweeklyType);
          }
        }
      });
    }

    setIsBiweeklyRequired(isBiweekly);
    addForm.setFieldsValue({ biweeklyType: newBiweeklyType });

  }, [schedules, rules, dateFrom]);

  return (
    <React.Fragment key="add_schedule">
      <Button
        type={"primary"}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("schedules.addScheduleRules")}
      </Button>
      <Modal
        open={isOpen}
        title={t("schedules.addScheduleRules")}
        onCancel={() => {
          addForm.resetFields();
          setOpen(false);
        }}
        onOk={() => addForm.submit()}
      >
        <Form
          form={addForm}
          onFinish={(values) => {
            let payload = {
              ...values,
              date_from: values.date_from.format("YYYY-MM-DD"),
              date_to: values.date_to ? values.date_to.format("YYYY-MM-DD") : null,
              biweekly_type: biweeklyType,
            };

            let newRuleState = {
              date_from: values.date_from.format("YYYY-MM-DD"),
              date_to: values.date_to ? values.date_to.format("YYYY-MM-DD") : null,
              offer_id: values.order_id,
              partner_id: values.partner_id,
              service_ratio: values.service_ratio,
              substitute_id: null,
              partner: partners.find((partner) => partner.id === values.partner_id),
              biweekly_type: biweeklyType,
            };

            coreApi
              .post("/schedules", payload)
              .then((res) => {
                newRuleState = {
                  ...newRuleState,
                  rules: [...res.data],
                };
                refreshScheduleList([...schedules, newRuleState]);
                addForm.resetFields();
                setOpen(false);
              })
              .catch((err) =>
                notification.error({
                  message:
                    err.response.data?.errors?.date_from ??
                    err.response.data?.errors?.date_to ??
                    err.response.data.message,
                })
              );
          }}
        >
          <Col>
            <Form.Item hidden name={"building_unit_id"} initialValue={buildingUnitId}></Form.Item>
          </Col>
          <Row gutter={[8, 0]}>
            <Col span={12} xs={12} md={12}>
              <Form.Item
                label={t("schedules.cleaningDay")}
                name={"cleaning_days"}
                rules={[
                  {
                    required: true,
                    message: t("schedules.validation.cleaningDay"),
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  showSearch={true}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {days.map((day) => (
                    <Select.Option key={day} value={day}>
                      {t("rrules.byweekday." + day)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} xs={12} md={12}>
              {isBiweeklyRequired && <BiweeklyTypeSelect
                postChange={(value) => {
                  setBiweeklyType(value);
                }}
                hasFeedback
                biweeklyType={biweeklyType}
                required={isBiweeklyRequired}
              />}
            </Col>
            <Col span={12}>
              <Form.Item
                label={t("schedules.dateFrom")}
                name={"date_from"}
                rules={[
                  {
                    required: true,
                    message: t("schedules.validation.dateFrom"),
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().startOf("month").format("YYYY-MM-DD")
                  }
                  onChange={(value) => {
                    setDateFrom(value);
                  }}
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("schedules.dateTo")} name={"date_to"}>
                <DatePicker
                  disabledDate={(current) =>
                    current.format("YYYY-MM-DD") !== current.clone().endOf("month").format("YYYY-MM-DD")
                  }
                ></DatePicker>
              </Form.Item>
            </Col>
            <Col span={12}>
              <PartnerSelect required label={t("schedules.partner")} name={"partner_id"} />
            </Col>
            <Col>
              <Form.Item
                label={t("schedules.serviceRatio")}
                name={"service_ratio"}
                initialValue={0.4}
                rules={[
                  {
                    required: true,
                    message: t("schedules.validation.serviceRatio"),
                  },
                ]}
              >
                <InputNumber step={0.01}></InputNumber>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label={t("schedules.substitute")}
                name={"substitute_id"}
              >
                <Select></Select>
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AddSchedule;
