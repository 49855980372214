import { Col, DatePicker, Form, notification, Row, Space, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { coreApi } from "../../api/calls";
import ScheduleMassTerminate from "../Scheduler/MultiStepEdit/ScheduleMultiStepMassEdit";
import RruleCell from "../Scheduler/RruleCell";
import MassInvoiceDuringMonthForm from "./Invoice/MassInvoiceDuringMonthForm";
import FrequencyCell from "../Scheduler/FrequencyCell";
import { ParseRegularityFromRruleDays } from "../../helpers";

const PartnerBuildingsListPane = ({ partner }) => {
  // Translations
  const { t } = useTranslation();

  // Default filters values
  let defaultFilters = {
    date_from: null,
    date_to: null,
    page: 1,
    page_size: 10,
    partner_id: partner?.id,
  };

  // States
  const [buildingUnits, setBuildingUnits] = useState();
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedBuildingUnits, setSelectedBuildingUnits] = useState([]);
  const [pagination, setPagination] = useState({});
  const [sorter, setSorter] = useState({});

  /**
   * Fetch data from API
   */
  const fetchData = useCallback(() => {
    setLoading(true);

    coreApi
      .get("/buildings/units/list", {
        params: {
          ...filters,
          date_from: filters?.date_from ? filters?.date_from.startOf("month").format("YYYY-MM-DD") : null,
          date_to: filters?.date_to ? filters?.date_to.endOf("month").format("YYYY-MM-DD") : null,
          partner_id: partner?.id,
          sorterOrder: sorter?.order,
          sorterKey: sorter?.columnKey,
          page_size: pagination?.pageSize,
          page: pagination?.current,
        },
      })
      .then((response) => {
        let { data, ...pagination } = response.data;

        setPagination(pagination);
        setBuildingUnits(data);
      })
      .catch((error) => {
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [partner, filters, pagination, sorter]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  /**
   * Handle change of the table (e. g. sorting trigger)
   */
  const handleTableChange = (pagination, tableFilters, sorter) => {
    setFilters({ ...filters, page_size: pagination?.pageSize, page: pagination?.current });
    setSorter(sorter);
    setPagination(pagination);
  };

  const selectRrules = (buildingUnitIds) => {
    setSelectedBuildingUnits(
      buildingUnits.filter((buildingUnit) => {
        return buildingUnitIds.includes(buildingUnit?.id);
      })
    );
  };

  const NestedTable = ({ rules }) => {
    return (
      <Table
        pagination={false}
        size={"small"}
        dataSource={rules}
        columns={[
          {
            title: t("schedules.schedule"),
            key: "cleaning_day",
            dataIndex: "rrule",
            render: (rrule) => {
              return <RruleCell rule={rrule} />;
            },
          },
          {
            title: t("schedules.dateFrom"),
            key: "date_from",
            dataIndex: "date_from",
            render: (dateFrom) => (dateFrom ? moment(dateFrom).format("LL") : ""),
          },
          {
            title: t("schedules.dateTo"),
            key: "date_to",
            dataIndex: "date_to",
            render: (dateTo) => (dateTo ? moment(dateTo).format("LL") : ""),
          },
        ]}
      ></Table>
    );
  };

  return (
    <React.Fragment>
      <Row>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Form.Item label={t("common.selectedPeriod")}>
            <DatePicker
              style={{ width: "160px" }}
              picker={"month"}
              format={"MMMM Y"}
              defaultValue={filters?.date_from}
              onChange={(selectedDate) => {
                setFilters({
                  ...filters,
                  date_from: moment(selectedDate).startOf("month"),
                  date_to: moment(selectedDate).endOf("month"),
                });
              }}
            />
          </Form.Item>

          <div>
            <MassInvoiceDuringMonthForm partner={partner} />
          </div>
        </div>

        <Col span={24}>
          <SlideDown className={"my-dropdown-slidedown"} closed={selectedRows.length < 1}>
            <Space style={{ paddingBottom: "16px" }}>
              <ScheduleMassTerminate selectedBuildingUnits={selectedBuildingUnits} />
            </Space>
          </SlideDown>
        </Col>
      </Row>
      <Table
        rowKey={"id"}
        size={"small"}
        rowSelection={{
          selectedRowKeys: selectedRows,
          selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
          onChange: (selectedRowKeys) => {
            selectRrules(selectedRowKeys);
            setSelectedRows(selectedRowKeys);
          },
        }}
        expandedRowRender={(buildingUnitRow) => {
          return <NestedTable rules={buildingUnitRow.service_rrules} />;
        }}
        pagination={{
          current: pagination.currentPage,
          pageSize: pagination.per_page,
          total: pagination.total,
          showSizeChanger: true,
          showTotal: (total, range) => (
            <p>
              {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
              {" " + t("common.paginationPartTwo") + " "}
              <strong>{total}</strong> {t("common.paginationPartThree")}
            </p>
          ),
        }}
        dataSource={buildingUnits}
        loading={loading}
        onChange={handleTableChange}
        columns={[
          {
            title: t("schedules.buildingUnit"),
            key: "street",
            sorter: true,
            render: (buildingUnit) =>
              buildingUnit?.building?.ending_date ? (
                <del>
                  <Link
                    to={"/buildings/" + buildingUnit.building_id + "/unit/" + buildingUnit.id}
                    style={{ fontWeight: 500 }}
                  >
                    {buildingUnit.street + " " + buildingUnit.house_number}
                  </Link>
                </del>
              ) : (
                <Link
                  to={"/buildings/" + buildingUnit.building_id + "/unit/" + buildingUnit.id}
                  style={{ fontWeight: 500 }}
                >
                  {buildingUnit.street + " " + buildingUnit.house_number}
                </Link>
              ),
          },
          {
            title: t("buildings.city"),
            sorter: (a, b) => a.building?.city_branch?.name?.localeCompare(b.building?.city_branch?.name),
            render: (data) => data.building?.city_branch?.name,
          },
          {
            title: t("buildings.district"),
            key: "district",
            dataIndex: "district",
            sorter: true,
          },
          {
            title: t("schedules.frequency"),
            key: "frequency",
            dataIndex: "service_rrules",
            render: (selectRrules) => {
              selectRrules.sort((a, b) => {
                if (a.cleaning_service_group?.sort_weight != null && b.cleaning_service_group?.sort_weight != null) {
                  return a.cleaning_service_group.sort_weight - b.cleaning_service_group.sort_weight;
                } else {
                  return a.id - b.id;
                }
              });
              return <FrequencyCell rule={selectRrules[0].rrule} />;
            }
          },
          {
            title: t("schedules.cleaningDays"),
            key: "cleaning_days",
            dataIndex: "cleaning_days",
            sorter: (a, b) => a.cleaning_days - b.cleaning_days,
            render: (days, element) => {
              return ParseRegularityFromRruleDays(days, element)
                .map((day) => {
                  if (day?.includes(",")) {
                    let moreDays = [];

                    day?.split(",").forEach((day) => {
                      moreDays.push(t("rrules.byweekday." + day));
                    });

                    return moreDays;
                  } else {
                    return t("rrules.byweekday." + day);
                  }
                })
                .join(", ");
            },
            onFilter: (value, record) => record.address.indexOf(value) === 0,
          },
        ]}
      />
    </React.Fragment>
  );
};

export default PartnerBuildingsListPane;
