import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { coreApi } from "../../api/calls";

const ContactSelect = ({
 id,
 label,
 required = true,
 initialValue = null,
}) => {
  const [contacts, setContacts] = useState([]);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    coreApi
      .get(`buildings/${id}`)
      .then((res) => {
        const loadedContacts = res.data.contacts;
        setContacts(loadedContacts);
        if (initialValue) {
          const contactExists = loadedContacts.some(
            (contact) => contact.id === initialValue
          );
          if (contactExists) {
            setSelectedValue(initialValue);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [id, initialValue]);

  return (
    <Form.Item
      name={"to"}
      label={label}
      rules={[{ required: required }]}
      initialValue={selectedValue}
    >
      <Select
        value={selectedValue ? selectedValue : undefined}
        onChange={(value) => setSelectedValue(value)}
        placeholder="Select a contact"
      >
        {contacts.map((contact) => (
          <Select.Option value={contact.id} key={contact.id}>
            {`${contact.first_name} ${contact.last_name} ${contact.email || ""}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ContactSelect;
