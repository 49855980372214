import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import ErrorPane from "./components/ErrorPane";
import PrivateRoute from "./components/PrivateRoute";
import SpinnerFullPage from "./components/SpinnerFullPage";
import Template from "./components/Template";
import AccountProfile from "./pages/AccountProfile";
import AccountsIndex from "./pages/AccountsIndex";
import BuildingProfile from "./pages/BuildingProfile";
import BuildingsIndex from "./pages/BuildingsIndex";
import BuildingUnitProfile from "./pages/BuildingUnitProfile";
import CityProfile from "./pages/CityProfile";
import CityReports from "./pages/CityReports";
import ClaimsIndex from "./pages/ClaimsIndex";
import CleaningsIndex from "./pages/CleaningsIndex";
import ClientLeadsIndex from "./pages/ClientLeadsIndex";
import ClientProfile from "./pages/ClientProfile";
import ClientsIndex from "./pages/ClientsIndex";
import ContactProfile from "./pages/ContactProfile";
import ContactsIndex from "./pages/ContactsIndex";
import CountriesIndex from "./pages/CountriesIndex";
import CountryProfile from "./pages/CountryProfile";
import BuildingUnitsIndex from "./pages/BuildingUnitsIndex";
import BuildingUnitSetupChangesIndex from "./pages/BuildingUnitSetupChangesIndex";
import CountryReports from "./pages/CountryReports";
import Dac7Reports from "./pages/Dac7Reports";
import Dashboard from "./pages/Dashboard";
import EmployeeProfile from "./pages/EmployeeProfile";
import EmployeesIndex from "./pages/EmployeesIndex";
import GoalsIndex from "./pages/GoalsIndex";
import InvoiceProfile from "./pages/InvoiceProfile";
import InvoicesIndex from "./pages/InvoicesIndex";
import Login from "./pages/Login";
// import ClientLeadProfile from "./pages/ClientLeadProfile";
import OfferProfile from "./pages/OfferProfile";
import OfferIndex from "./pages/OffersIndex";
import PartnerLeadProfile from "./pages/PartnerLeadProfile";
import PartnerLeadsIndex from "./pages/PartnerLeadsIndex";
import PartnerProfile from "./pages/PartnerProfile";
import PartnersIndex from "./pages/PartnersIndex";
import PaymentsIndex from "./pages/PaymentsIndex";
import Profile from "./pages/Profile";
import TasksIndex from "./pages/TasksIndex";
import TransactionProfile from "./pages/TransactionProfile";
import UnassignedTransactionsIndex from "./pages/UnassignedTransactionsIndex";
import PaymentsForApprovalIndex from "./pages/PaymentsForApprovalIndex";
import * as Sentry from "@sentry/react";
import MaintenancePage from "./pages/MaintenancePage";
import CommandsIndex from "./pages/CommandsIndex";
import EmailsIndex from "./pages/EmailsIndex";

const App = () => {
  const { t } = useTranslation();

  const routeList = [
    {
      path: "/",
      component: Dashboard,
      name: t("breadcrumbs.dashboard"),
    },
    {
      path: "/dashboard",
      component: Dashboard,
      name: t("breadcrumbs.dashboard"),
    },
    {
      path: "/tasks",
      component: TasksIndex,
      name: t("breadcrumbs.tasks"),
    },
    {
      path: "/goals",
      component: GoalsIndex,
      name: t("breadcrumbs.goals"),
    },
    {
      path: "/buildings/:building_id/unit/:id",
      component: BuildingUnitProfile,
      name: t("breadcrumbs.buildingUnitProfile"),
    },
    {
      path: "/buildings",
      component: BuildingsIndex,
      name: t("breadcrumbs.buildings"),
    },
    {
      path: "/buildings/:id",
      component: BuildingProfile,
      name: t("breadcrumbs.buildingProfile"),
    },
    {
      path: "/building-units",
      component: BuildingUnitsIndex,
      name: t("breadcrumbs.buildingUnits"),
    },
    {
      path: "/building-unit-setup-changes",
      component: BuildingUnitSetupChangesIndex,
      name: t("breadcrumbs.buildingUnitSetupChanges"),
    },
    {
      path: "/partners",
      component: PartnersIndex,
      name: t("breadcrumbs.partners"),
    },
    {
      path: "/partners/:id",
      component: PartnerProfile,
      name: t("breadcrumbs.partnerProfile"),
    },
    {
      path: "/clients/:id",
      component: ClientProfile,
      name: t("breadcrumbs.clientProfile"),
    },
    {
      path: "/clients",
      component: ClientsIndex,
      name: t("breadcrumbs.clients"),
    },
    {
      path: "/cleanings",
      component: CleaningsIndex,
      name: t("breadcrumbs.cleanings"),
    },
    {
      path: "/invoices",
      component: InvoicesIndex,
      name: t("breadcrumbs.invoices"),
    },
    {
      path: "/invoices/:id",
      component: InvoiceProfile,
      name: t("breadcrumbs.invoiceProfile"),
    },
    {
      path: "/offers/:id",
      component: OfferProfile,
      name: t("breadcrumbs.offerProfile"),
    },
    {
      path: "/offers",
      component: OfferIndex,
      name: t("breadcrumbs.offers"),
    },
    {
      path: "/orders/:id",
      component: OfferProfile,
      name: t("breadcrumbs.orderProfile"),
    },
    {
      path: "/accounts",
      component: AccountsIndex,
      name: t("breadcrumbs.accounts"),
    },
    {
      path: "/accounts/:id",
      component: AccountProfile,
      name: t("breadcrumbs.accountProfile"),
    },
    {
      path: "/transactions",
      component: PaymentsIndex,
      name: t("breadcrumbs.transactions"),
    },
    {
      path: "/transactions/:id",
      component: TransactionProfile,
      name: t("breadcrumbs.transactionProfile"),
    },
    {
      path: "/partner-leads",
      component: PartnerLeadsIndex,
      name: t("breadcrumbs.partnerLeads"),
    },
    {
      path: "/partner-leads/:id",
      component: PartnerLeadProfile,
      name: t("breadcrumbs.partnerLeadsProfile"),
    },
    {
      path: "/client-leads",
      component: ClientLeadsIndex,
      name: t("breadcrumbs.clientLeads"),
    },
    {
      path: "/countries",
      component: CountriesIndex,
      name: t("breadcrumbs.countries"),
    },
    {
      path: "/countries/:id",
      component: CountryProfile,
      name: t("breadcrumbs.countryProfile"),
    },
    {
      path: "/countries/:country_id/cities/:id",
      component: CityProfile,
      name: t("breadcrumbs.cityProfile"),
    },
    {
      path: "/employees",
      component: EmployeesIndex,
      name: t("breadcrumbs.employees"),
      exact: true,
    },
    {
      path: "/employees/:id",
      component: EmployeeProfile,
      name: t("breadcrumbs.employeeProfile"),
    },
    {
      path: "/profile",
      component: Profile,
      name: t("breadcrumbs.profile"),
    },
    // {
    //   path: "/payments",
    //   component: PaymentsIndex,
    //   name: t("breadcrumbs.payments"),
    // },
    {
      path: "/unlinked-transactions",
      component: UnassignedTransactionsIndex,
      name: t("breadcrumbs.unlinkedTransactions"),
    },
    {
      path: "/payments-for-approval",
      component: PaymentsForApprovalIndex,
      name: t("breadcrumbs.paymentsForApproval"),
    },
    {
      path: "/claims",
      component: ClaimsIndex,
      name: t("breadcrumbs.claims"),
    },
    {
      path: "/contacts",
      component: ContactsIndex,
      name: t("breadcrumbs.contacts"),
    },
    {
      path: "/contacts/:id",
      component: ContactProfile,
      name: t("breadcrumbs.contactProfile"),
    },
    {
      path: "/city-reports",
      component: CityReports,
      name: t("breadcrumbs.cityReports"),
    },
    {
      path: "/country-reports",
      component: CountryReports,
      name: t("breadcrumbs.countryReports"),
    },
    {
      path: "/dac7-reports",
      component: Dac7Reports,
      name: t("breadcrumbs.dac7Reports"),
    },
    {
      path: "/commands",
      component: CommandsIndex,
      name: t("breadcrumbs.adminTools"),
    },
    {
      path: "/emails",
      component: EmailsIndex,
      name: t("breadcrumbs.emails"),
    },
    {
      path: "/*",
      component: () => <ErrorPane errorCode={404} />,
      name: t("breadcrumbs.countryReports"),
    },
  ];

  return process.env.REACT_APP_SITE_MODE === "LIVE" ? (
    <Suspense fallback={<SpinnerFullPage />}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Template routeList={routeList}>
            <Switch>
              {routeList.map((route) => (
                <PrivateRoute key={route.path} path={route.path} name={route.name} component={route.component} exact />
              ))}
            </Switch>
          </Template>
        </Switch>
      </BrowserRouter>
    </Suspense>
  ) : (
    <MaintenancePage />
  );
};

export default Sentry.withProfiler(App);
