import React, { useEffect, useState } from "react";
import { Table, Tooltip, Tag, Space, Button, Modal, notification, Popconfirm, Radio, Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import {
  InfoCircleOutlined,
  DeleteOutlined,
  SaveOutlined,
  FilePdfOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AddForm from "../components/Buildings/BuildingUnitSetupChanges/AddForm";
import { coreApi } from "../api/calls";
import EmployeeLink from "../components/Employees/EmployeeLink";
import BuildingUnitLink from "../components/Buildings/BuildingUnitLink";
import fileDownload from "js-file-download";
import LanguageSelect from "../components/LanguageSelect";
import PartnerLink from "../components/Partners/PartnerLink";
import PartnerSelect from "../components/Partners/PartnerSelect";
import RruleDaySelect from "../components/Scheduler/RruleDaySelect";
import { rrulestr } from "rrule";
import { getAreaScope } from "../actions";
import { useSelector } from "react-redux";
import { sortPartners } from "../components/helpers/sort";
import MassEditForm from "../components/Buildings/BuildingUnitSetupChanges/MassEditForm";
import EditManyForm from "../components/Buildings/BuildingUnitSetupChanges/EditManyForm";
import { fetchManagers } from "../api/managerApi";

const BuildingUnitSetupChangesIndex = () => {
  const { t, i18n } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));

  const areaScope = useSelector(getAreaScope);
  const selectedCity = areaScope.areaScope;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditManyModalVisible, setIsEditManyModalVisible] = useState(false);
  const [isMassEditModalVisible, setIsMassEditModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [sorter, setSorter] = useState({});
  const [managers, setManagers] = useState([]);
  const [actions, setActions] = useState([]);
  const [filters, setFilters] = useState({
    manager: [user.employee_id],
    status_id: [selectedStatus],
  });

  const [productType, setProductType] = useState("apartment_buildings");
  const [displayPrice, setDisplayPrice] = useState("total");
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language || "cs");

  const [defaultPartners, setDefaultPartners] = useState([]);

  const [formKeys, setFormKeys] = useState({
    addForm: Math.random(),
    massEditForm: Math.random(),
    editManyForm: Math.random(),
    downloadForm: Math.random(),
  });

  const fetchBuildingUnitSetupChanges = (paginationParams = {}, sorterParams = {}, filtersParams = {}) => {
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);

    const { current, pageSize } = paginationParams;
    const { order, field } = sorterParams;

    const filterParams = Object.keys(filtersParams).reduce((acc, key) => {
      acc[key] = filtersParams[key]?.join(",") || null;
      return acc;
    }, {});

    filterParams.city_branch_id = selectedCity || null;

    coreApi
      .get("/building-unit-setup-changes", {
        params: {
          page: current,
          per_page: pageSize,
          order: order === "descend" ? "desc" : "asc",
          sort_by: field || "action_week_from",
          ...filterParams,
        },
      })
      .then((response) => {
        if (!signal.aborted) {
          setSelectedRowKeys([]);
          const rowData = calculateRowSpan(response.data.data);
          setData(rowData);
          setPagination({
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
          });
        }
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") + (error.response?.data?.message || "") });
      })
      .finally(() => setLoading(false));

    return () => controller.abort();
  };

  const fetchSetupChangesStatuses = async () => {
    coreApi
      .get("/building-unit-setup-changes/actions/statuses")
      .then((response) => {
        setStatuses(response.data);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") + (error.response?.data?.message || "") });
      });
  };

  const getManagers = async () => {
    try {
      const sortedManagers = await fetchManagers();
      setManagers(sortedManagers);
    } catch (error) {
      notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") });
    }
  };

  const fetchPartners = async () => {
    coreApi.get("/partners/all")
      .then(response => {
        setDefaultPartners(sortPartners(response.data));
      })
      .catch(error => {
        notification.error({ message: t("common.loadFailed") });
      });
  };

  const fetchActions = async () => {
    coreApi
      .get("/building-unit-setup-changes/actions/list-actions")
      .then((response) => {
        setActions(response.data);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") + (error.response?.data?.message || "") });
      });
  };

  const calculateRowSpan = (data) => {
    const groupingCounts = data.reduce((acc, item) => {
      const key = item.grouping_param;
      if (!acc[key]) {
        acc[key] = { count: 0, index: Object.keys(acc).length + 1 };
      }
      acc[key].count++;
      return acc;
    }, {});

    return data.map((item, index, arr) => {
      const isRowSpanStart = index === 0 || item.grouping_param !== arr[index - 1].grouping_param;
      return {
        ...item,
        rowSpan: isRowSpanStart ? groupingCounts[item.grouping_param].count : 0,
        rowSpanIndex: isRowSpanStart ? groupingCounts[item.grouping_param].index : null,
        rowClass: isRowSpanStart ? "grouped-row" : "",
      };
    });
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    coreApi
      .put("/building-unit-setup-changes/actions/update-statuses", {
        ids: selectedRowKeys,
        status: value,
      })
      .then((response) => {
        notification.success({ message: t("buildingSetupChanges.messages.statusUpdated") });
        setSelectedRowKeys([]);
        fetchBuildingUnitSetupChanges(pagination, sorter, filters);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedUpdateStatus") + (error.response?.data?.message || "") });
      });
  };

  const handleDeleteRow = (id) => {
    coreApi
      .delete(`/building-unit-setup-changes/${id}`)
      .then((response) => {
        notification.success({ message: t("common.recordDeleted") });
        fetchBuildingUnitSetupChanges(pagination, sorter, filters);
      })
      .catch((error) => {
        notification.error({ message: t("common.deletionFailed") + (error.response?.data?.message || "") });
      });
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    const updatedFilters = { ...filters };

    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key]?.length > 0) {
        updatedFilters[key] = tableFilters[key];
      } else {
        updatedFilters[key] = null;
      }
    });

    let sorterParams = {};

    if (sorter.order) {
      sorterParams = {
        order: sorter.order,
        field: sorter.field || sorter.columnKey || "id",
      };
    } else {
      sorterParams = {
        order: null,
        field: null,
      };
    }

    setFilters(updatedFilters);
    setPagination(pagination);
    setSorter(sorterParams);

    fetchBuildingUnitSetupChanges(pagination, sorterParams, updatedFilters);
  };

  const handleDownloadPdf = () => {
    if (!productType || displayPrice === null) {
      notification.error({ message: t("buildingSetupChanges.messages.selectOptions") });
      return;
    }

    coreApi
      .post(
        "/building-unit-setup-changes/actions/download/pdf",
        { ids: selectedRowKeys, productType, displayPrice, language: selectedLanguage },
        { responseType: "blob" },
      )
      .then((response) => {
        fileDownload(response.data, "Nabídka_zakázek.pdf");
        setIsDownloadModalVisible(false);
      })
      .catch((error) => {
        notification.error({ message: t("buildingSetupChanges.messages.failedDownloadPdf") + (error.response?.data?.message || "") });
      });
  };

  const formatRruleDays = (rrule) => {
    if (!rrule) {
      return "";
    }

    try {
      const daysMap = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
      const daysNames = {
        MO: t("rrules.byweekday.MO"),
        TU: t("rrules.byweekday.TU"),
        WE: t("rrules.byweekday.WE"),
        TH: t("rrules.byweekday.TH"),
        FR: t("rrules.byweekday.FR"),
        SA: t("rrules.byweekday.SA"),
        SU: t("rrules.byweekday.SU"),
      };
      const rruleObject = rrulestr(rrule);

      if (!rruleObject.origOptions.byweekday) {
        return "";
      }

      const selectedDays = rruleObject.origOptions.byweekday.map((weekday) => daysMap[weekday.weekday]);
      return selectedDays.map(day => daysNames[day]).join(", ");
    } catch (error) {
      console.error("Invalid rrule format", error);
      return "";
    }
  };

  const handleRruleChange = (rruleString, id) => {
    const newData = data.map(item => {
      if (item.id === id) {
        return { ...item, substitute_rrule: rruleString };
      }
      return item;
    });
    setData(newData);

    // Save the updated rrule to the backend
    coreApi
      .put(`/building-unit-setup-changes/actions/${id}/update-rrule`, { substitute_rrule: rruleString })
      .then((response) => {
        notification.success({ message: t("common.saveSuccess") });
      })
      .catch((error) => {
        notification.error({ message: t("common.saveFailed") + (error.response?.data?.message || "") });
      });
  };

  const handleSubstitutePartnerChange = (partner, recordId) => {
    coreApi
      .put(`/building-unit-setup-changes/actions/${recordId}/update-substitute-partner`, { substitute_partner_id: partner.id })
      .then((response) => {
        notification.success({ message: t("common.saveSuccess") });
        fetchBuildingUnitSetupChanges(pagination, sorter, filters);
      })
      .catch((error) => {
        notification.error({ message: t("common.saveFailed") + (error.response?.data?.message || "") });
      });
  };

  useEffect(() => {
    fetchSetupChangesStatuses();
    getManagers();
    fetchActions();
    fetchPartners();
  }, []);

  useEffect(() => {
    fetchBuildingUnitSetupChanges(pagination, sorter, filters);
  }, [selectedCity]);

  const renderRruleDaySelect = (rrule, record) => (
    <Form>
      <RruleDaySelect
        size="small"
        className="mb-0"
        rrule={rrule}
        postBlur={(rruleString) => handleRruleChange(rruleString, record.id)}
        showLabel={false}
      />
    </Form>
  );

  const columns = [
    {
      title: t("schedules.status"),
      key: "status_id",
      dataIndex: "status_id",
      sorter: true,
      width: 108,
      fixed: "left",
      filteredValue: filters.status_id,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 250 }}>
          <Space direction="vertical">
            <Select
              mode="multiple"
              allowClear
              style={{ width: 220 }}
              placeholder={t("buildingSetupChanges.selectStatus")}
              value={selectedKeys}
              onChange={(value) => {
                setSelectedKeys(value);
                const newFilters = {
                  ...filters,
                  status_id: value.length > 0 ? value : null,
                };
                setFilters(newFilters);
                fetchBuildingUnitSetupChanges(pagination, sorter, newFilters);
                confirm();
              }}
            >
              {statuses.map((status) => (
                <Select.Option key={status.id} value={status.id}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </div>
      ),
      render: (text, record) =>
        record.status_id === 1 ? (
          <Tag color="orange">{t("buildingSetupChanges.statusInSolution")}</Tag>
        ) : (
          <Tag color="green">{t("buildingSetupChanges.statusSolved")}</Tag>
        ),
    },
    {
      title: t("common.address"),
      sorter: true,
      key: "address",
      width: 220,
      fixed: "left",
      render: (text, record) => <BuildingUnitLink buildingUnit={record.building_unit} />,
    },
    {
      title: t("common.city"),
      key: "city",
      width: 150,
      fixed: "left",
      sorter: (a, b) => a.city_branch.name.localeCompare(b.city_branch.name),
      render: (text, record) => record.city_branch.name,
    },
    {
      title: t("expansion.managerName"),
      key: "manager",
      dataIndex: "manager_first_name",
      sorter: true,
      width: 160,
      fixed: "left",
      filteredValue: filters.manager || [],
      render: (text, record) => (record.manager ? <EmployeeLink employee={record.manager} /> : ''),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 250 }}>
          <Space direction="vertical">
            <Select
              mode="multiple"
              allowClear
              style={{ width: 220 }}
              placeholder={t("managers.selectManager")}
              value={selectedKeys}
              onChange={(value) => {
                setSelectedKeys(value);
                const newFilters = {
                  ...filters,
                  manager: value.length > 0 ? value : null,
                };
                setFilters(newFilters);
                fetchBuildingUnitSetupChanges(pagination, sorter, newFilters);
                confirm();
              }}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              optionLabelProp="label"
            >
              {managers.map((manager) => (
                <Select.Option
                  key={manager.id}
                  value={manager.id}
                  label={`${manager.first_name} ${manager.last_name}`}
                >
                  {manager.user?.deleted_at === null ? (
                    `${manager.first_name} ${manager.last_name}`
                  ) : (
                    <s>{`${manager.first_name} ${manager.last_name}`}</s>
                  )}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </div>
      ),
    },
    {
      title: t("buildingSetupChanges.action"),
      key: "action_id",
      dataIndex: "action_tag_name",
      sorter: true,
      width: 195,
      fixed: "left",
      filters: actions.map(action => ({
        text: action.translations.find(translation => translation.language_ietf === "cs").tag_name,
        value: action.id,
      })),
      filteredValue: filters.action_id || null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8, width: 250 }}>
          <Space direction="vertical">
            <Select
              mode="multiple"
              allowClear
              style={{ width: 220 }}
              placeholder={t("buildingSetupChanges.selectAction")}
              value={selectedKeys}
              onChange={(value) => {
                setSelectedKeys(value);
                const newFilters = {
                  ...filters,
                  action_id: value.length > 0 ? value : null,
                };
                setFilters(newFilters);
                fetchBuildingUnitSetupChanges(pagination, sorter, newFilters);
                confirm();
              }}
            >
              {actions.map((action) => (
                <Select.Option key={action.id} value={action.id}>
                  {action.translations.find(translation => translation.language_ietf === "cs").tag_name}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </div>
      ),
      render: (text, record) => {
        const actionTranslation = record.action?.translations.find((translation) => translation.language_ietf === "cs");
        const displayText = actionTranslation ? actionTranslation.tag_name : text;

        return (
          <Tooltip title={displayText}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("buildingSetupChanges.actionWeek"),
      key: "action_week_from",
      dataIndex: "action_week_from",
      sorter: true,
      width: 120,
      fixed: "left",
      render: (text, record) => {
        const formatDate = (date) => {
          const d = new Date(date);
          return `${String(d.getDate()).padStart(2, "0")}-${String(d.getMonth() + 1).padStart(2, "0")}`;
        };
        return record.action_week_to ? `${formatDate(record.action_week_from)} - ${formatDate(record.action_week_to)}` : `${formatDate(record.action_week_from)}`;
      },
    },
    {
      title: t("common.partner"),
      key: "partner",
      dataIndex: "partner_first_name",
      sorter: true,
      width: 150,
      filteredValue: filters.partner || null,
      onFilter: (value, record) => record.partner && record.partner.id === value,
      render: (text, record) => {
        const partner = record.partner || (record.active_order && record.active_order[0]?.client);
        return partner ? <PartnerLink partner={partner} /> : <span>-</span>;
      },
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8, width: 280 }}>
          <PartnerSelect
            onChange={(value) => {
              const newFilters = { ...filters, partner: value ? [value.id] : null };
              setFilters(newFilters);
              fetchBuildingUnitSetupChanges(pagination, sorter, newFilters);
              confirm();
            }}
            defaultPartners={defaultPartners}
            allowClear
            style={{ width: 200 }}
          />
        </div>
      ),
    },
    {
      title: t("common.day"),
      key: "day",
      dataIndex: "day",
      width: 120,
      render: (text, record) => {
        return record.rrule ? (
          <Tooltip title={formatRruleDays(record.rrule, t)}>
            {formatRruleDays(record.rrule, t)}
          </Tooltip>
        ) : (
          <Tooltip title={record.day}>
            <span>{record.day}</span>
          </Tooltip>
        );
      },
    },
    {
      title: t("common.substitutePartner"),
      key: "substitute_partner",
      dataIndex: "substitute_partner_first_name",
      sorter: true,
      width: 180,
      filteredValue: filters.substitute_partner || null,
      onFilter: (value, record) => record.substitute_partner && record.substitute_partner.id === value,
      render: (text, record) => {
        if (!record.substitute_partner && record.status_id === 1) {
          return (
            <Form>
              <PartnerSelect
                size="small"
                className="mb-0"
                defaultPartners={defaultPartners}
                name={"substitute_partner_id"}
                onChange={(value) => handleSubstitutePartnerChange(value, record.id)}
              />
            </Form>
          );
        } else {
          return record.substitute_partner ? <PartnerLink partner={record.substitute_partner} /> : <span></span>;
        }
      },
      filterDropdown: ({ confirm }) => (
        <div style={{ padding: 8, width: 280 }}>
          <PartnerSelect
            onChange={(value) => {
              const newFilters = { ...filters, substitute_partner: value ? [value.id] : null };
              setFilters(newFilters);
              fetchBuildingUnitSetupChanges(pagination, sorter, newFilters);
              confirm();
            }}
            defaultPartners={defaultPartners}
            allowClear
            style={{ width: 200 }}
          />
        </div>
      ),
    },
    {
      title: t("common.substituteDay"),
      key: "new_day",
      dataIndex: "new_day",
      width: 140,
      render: (text, record) => {
        const rrule = record.substitute_rrule || record.rrule;
        const isEditable = !record.substitute_rrule && record.status_id === 1;

        return isEditable ? renderRruleDaySelect(rrule, record) : (
          <Tooltip title={formatRruleDays(rrule, t)}>
            <span>{formatRruleDays(rrule, t) || record.new_day}</span>
          </Tooltip>
        );
      },
    },
    {
      title: (
        <span>
          {t("buildingSetupChanges.partnerReward")}{" "}
          <Tooltip title="(activeOrderPrice * 0.6) / buildingUnitsCount">
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      key: "partner_reward",
      dataIndex: "price_per_unit",
      sorter: true,
      width: 100,
      render: (text, record) => {
        if (record?.price_per_unit) {
          const totalAmount = parseFloat(record.offers_price_total_amount);
          const buildingUnitsCount = record.building_units_count;
          const partnerReward = record?.price_per_unit || 0;

          const res = Number(partnerReward).toFixed(2);
          const tooltipTitle = `(${totalAmount} * 0.6) / ${buildingUnitsCount}`;

          return (
            <span>
              {res}{" "}
              <Tooltip title={tooltipTitle}>
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: t("common.district"),
      key: "district",
      dataIndex: "building_unit.district",
      width: 150,
      render: (text, record) => {
        return <div>{record.building_unit.district}</div>;
      },
    },
    {
      title: t("buildingSetupChanges.cleaningFrequency"),
      key: "building_unit.service_rrules.sort_weight",
      dataIndex: "building_unit",
      sorter: false,
      width: 130,
      render: (building_unit) => {
        const sortedRrules = (building_unit?.service_rrules || [])
          .filter((rrule) => rrule.rrule?.includes("WEEKLY"))
          .sort((a, b) => {
            return (a.cleaning_service_group?.sort_weight || 0) - (b.cleaning_service_group?.sort_weight || 0);
          });

        if (sortedRrules.length > 0) {
          return sortedRrules[0] ? sortedRrules[0].cleaning_service_group.name : "-";
        }

        return "-";
      },
    },
    {
      title: t("buildingSetupChanges.floorCount"),
      key: "floor_count",
      width: 120,
      render: (text, record) =>
        `${record.building_unit.floor_count}, ${record.building_unit.ground_floor_count}, ${record.building_unit.basement_floor_count}`,
    },
    {
      title: t("common.notes"),
      key: "notes",
      width: 250,
      render: (text, record) => {
        return (
          <Tooltip title={record.notes}>
            {record.notes || "-"}
          </Tooltip>
        );
      },
    },
    {
      title: t("common.actions"),
      key: "actions",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title={t("common.confirmDelete")}
            onConfirm={() => handleDeleteRow(record.id)}
            okText={t("common.confirmAnswer")}
            cancelText={t("common.rejectAnswer")}
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const hideAddModal = () => {
    resetFormKey("addForm");
    setIsAddModalVisible(false);
  };

  const showDownloadModal = () => {
    setIsDownloadModalVisible(true);
  };

  const hideDownloadModal = () => {
    resetFormKey("downloadForm");
    setIsDownloadModalVisible(false);
  };

  const showMassEditModal = () => {
    const selectedUnits = data.filter((unit) => selectedRowKeys.includes(unit.id));
    if (selectedUnits.length === 0) {
      notification.error({ message: t("buildingSetupChanges.selectRowToEdit") });
      return;
    }
    setIsMassEditModalVisible(true);
  };

  const hideMassEditModal = () => {
    resetFormKey("massEditForm");
    setIsMassEditModalVisible(false);
  };

  const hideEditManyModal = () => {
    resetFormKey("editManyForm");
    setIsEditManyModalVisible(false);
  };

  const resetFormKey = (formName) => {
    setFormKeys((prevKeys) => ({
      ...prevKeys,
      [formName]: Math.random(),
    }));
  };

  return (
    <div>
      <Space style={{ marginBottom: 16 }}>
        <Button
          size="small"
          type="primary"
          onClick={showAddModal}
          icon={<PlusOutlined />}
        >
          {t("buildings.add")}
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<FilePdfOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={showDownloadModal}
        >
          {t("buildingSetupChanges.downloadPdf")}
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<EditOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={showMassEditModal}
        >
          {t("common.identicalEdit")}
        </Button>
        <Button
          size="small"
          type="primary"
          icon={<EditOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={() => setIsEditManyModalVisible(true)}
        >
          {t("common.differentEdit")}
        </Button>

        <Button
          type="ghost"
          size="small"
          icon={<SaveOutlined />}
          disabled={selectedRowKeys.length === 0}
          onClick={() => handleStatusChange(2)}
          className="success-button"
        >
          {t("buildingSetupChanges.statusSolved")}
        </Button>
      </Space>
      <Table
        size="small"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        loading={loading}
        bordered={true}
        rowKey="id"
        scroll={{ x: 1500, y: 500 }}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          showTotal: (total, range) => (
            <p>
              {t("common.paginationPartOne")} <strong>{range[0]}</strong>-<strong>{range[1]}</strong>
              {" " + t("common.paginationPartTwo") + " "}
              <strong>{total}</strong> {t("common.paginationPartThree")}
            </p>
          ),
        }}
        sticky
        onChange={handleTableChange}
        rowClassName={(record, index) => record.rowClass}
      />

      <Modal title={t("buildings.add")} open={isAddModalVisible} onCancel={hideAddModal} footer={null}>
        {isAddModalVisible && (
          <AddForm
            key={formKeys.addForm}
            onFormSubmit={() => {
              hideAddModal();
              fetchBuildingUnitSetupChanges(pagination, sorter, filters);
            }}
          />
        )}
      </Modal>

      <Modal
        key={formKeys.downloadForm}
        title={t("buildingSetupChanges.downloadPdf")}
        open={isDownloadModalVisible}
        onCancel={hideDownloadModal}
        onOk={handleDownloadPdf}
      >
        <div>
          <h4>{t("buildingSetupChanges.selectProductType")}</h4>
          <Radio.Group
            className="vertical-radio-group"
            onChange={(e) => setProductType(e.target.value)}
            value={productType}
          >
            <Radio value="apartment_buildings">
              {t("buildingSetupChanges.offerForApartmentBuildings")}
            </Radio>
            <Radio value="commercial_spaces" disabled>
              {t("buildingSetupChanges.offerForCommercialSpaces")}
            </Radio>
          </Radio.Group>
        </div>
        <div style={{ marginTop: 16 }}>
          <h4>{t("buildingSetupChanges.selectRewardDisplay")}</h4>
          <Radio.Group
            className="vertical-radio-group"
            onChange={(e) => setDisplayPrice(e.target.value)}
            value={displayPrice}
          >
            <Radio value="total">
              {t("buildingSetupChanges.createPdfWithTotalReward")}
            </Radio>
            <Radio value="separate">
              {t("buildingSetupChanges.createPdfWithSeparateReward")}
            </Radio>
            <Radio value="none">
              {t("buildingSetupChanges.createPdfWithoutReward")}
            </Radio>
          </Radio.Group>
        </div>
        <div style={{ marginTop: 16 }}>
          <LanguageSelect
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          />
        </div>
      </Modal>
      <Modal
        title={t("common.identicalEdit")}
        open={isMassEditModalVisible}
        onCancel={hideMassEditModal}
        footer={null}
      >
        {isMassEditModalVisible && (
          <MassEditForm
            key={formKeys.massEditForm}
            selectedUnits={data.filter((unit) => selectedRowKeys.includes(unit.id))}
            onSave={() => {
              hideMassEditModal();
              fetchBuildingUnitSetupChanges(pagination, sorter, filters);
            }}
            onCancel={hideMassEditModal}
          />
        )}
      </Modal>
      <Modal
        width={1200}
        title={t("common.differentEdit")}
        open={isEditManyModalVisible}
        onCancel={hideEditManyModal}
        footer={null}
      >
        {isEditManyModalVisible && (
          <EditManyForm
            key={formKeys.editManyForm}
            onSave={() => {
              hideEditManyModal()
              fetchBuildingUnitSetupChanges(pagination, sorter, filters);
            }}
            items={data.filter((unit) => selectedRowKeys.includes(unit.id))}
            onCancel={hideEditManyModal}
          />
        )}
      </Modal>
    </div>
  );
};

export default BuildingUnitSetupChangesIndex;
