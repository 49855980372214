import { Card, Col, Row, Table, Tag, Button } from "antd";
import React, { useEffect, useState } from "react";
import { coreApi } from "../api/calls";
import Command from "./../components/Commands/Command";
import moment from "moment";
import ImportCustomCsv from "./../components/Import/ImportCustomCsv";
import ImportOrdersPrice from "./../components/Import/ImportOrdersPrice";
import { ImportOutlined } from "@ant-design/icons";

const CommandsIndex = () => {
  const [audits, setAudits] = useState([]);
  const [isImportOrdersPriceModalOpen, setIsImportOrdersPriceModalOpen] = useState(false);
  const [isImportCustomCsvModalOpen, setIsImportCustomCsvModalOpen] = useState(false);

  useEffect(() => {
    coreApi
      .get("/commands")
      .then((res) => setAudits(res.data))
      .catch((err) => console.log(err?.response?.data?.message));
  }, []);

  const handleCloseImportCustomCsvModal = () => {
    setIsImportCustomCsvModalOpen(false);
  };

  const handleCloseImportOrdersPriceModal = () => {
    setIsImportOrdersPriceModalOpen(false);
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card title="Available commands">
            <Command title={"Recalculate client balance"} apiURL={"/commands/client-balance"} />
            {/* Script causing overpayment for clients as the amount of the split transaction between two invoice was recounted to its original value. */}
            {/* <Command title={"Fix transaction amount"} apiURL={"/commands/transaction-amount"} /> */}
            <Command title={"Fix transaction amount used"} apiURL={"/commands/transaction-amount-used"} />
            <Command title={"Fix invoices paid status"} apiURL={"/commands/paid-invoices"} />
            <Command title={"Generate missing invoice tokens"} apiURL={"/commands/add-tokens"} />
            {/* <Command title={"Refresh elasticsearch data"} apiURL={"/commands/elastic-reimport"} /> */}
            {<Command title={"Incremental import elasticsearch data"} apiURL={"/commands/elastic-incremental-import"} /> }
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Active jobs">
            <Table
              size="small"
              dataSource={audits}
              pagination={false}
              rowKey="id"
              columns={[
                {
                  title: "Command",
                  dataIndex: "command",
                  ellipsis: true,
                },
                {
                  title: "Started at",
                  width: "30%",
                  render: (row) => moment(row.created_at).format("DD. MM. YYYY HH:mm:ss"),
                },
                {
                  title: "Status",
                  width: "15%",
                  render: (row) => {
                    switch (row.status.scalar) {
                      case "failed":
                        return <Tag color="error">{row.status.scalar}</Tag>;
                      case "pending":
                        return <Tag color="processing">{row.status.scalar}</Tag>;
                      case "processed":
                        return <Tag color="success">{row.status.scalar}</Tag>;

                      default:
                        return <Tag>{row?.status.scalar}</Tag>;
                    }
                  },
                },
                {
                  title: "User",
                  render: (row) => row.email.scalar,
                },
              ]}
            ></Table>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Actions">
            <Button
              type="primary"
              onClick={() => setIsImportOrdersPriceModalOpen(true)}
              icon={<ImportOutlined />}
            >
              Bulk change price in the orders
            </Button>
          </Card>
        </Col>
        {isImportOrdersPriceModalOpen && (
          <ImportOrdersPrice
            isVisible={isImportOrdersPriceModalOpen}
            onClose={handleCloseImportOrdersPriceModal}
          />
        )}
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Import data">
            <Button
              type="primary"
              onClick={() => setIsImportCustomCsvModalOpen(true)}
              icon={<ImportOutlined />}
            >
              Import custom csv to temporary table
            </Button>
          </Card>
        </Col>
        {isImportCustomCsvModalOpen && (
          <ImportCustomCsv
            isVisible={isImportCustomCsvModalOpen}
            onClose={handleCloseImportCustomCsvModal}
          />
        )}
      </Row>
    </React.Fragment>
  );
};

export default CommandsIndex;