import {
  BankOutlined,
  BarChartOutlined,
  DashboardOutlined,
  DollarOutlined,
  EuroCircleOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FullscreenOutlined,
  HomeOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  SwapOutlined,
  TeamOutlined,
  UserOutlined,
  ShopOutlined,
  MailOutlined,
  ToolOutlined,
  ClearOutlined,
  TransactionOutlined,
  FileOutlined
} from "@ant-design/icons";
import {Layout, Menu, Tooltip} from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import mainLogo from "../images/mainLogo.png";

const { Sider } = Layout;

const SideNav = () => {
  const { t } = useTranslation();
  const [role, setRole] = useState({});

  // Set role of the user
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));

    setRole(user?.roles[user?.roles.length - 1]);
  }, []);

  return (
    <Sider width={50} trigger={null} collapsed={true} style={{ position: "fixed", height: "100vh", zIndex: 100 }}>
      <div className="logo" style={{ marginTop: "4px" }}>
        <Link to="/dashboard">
          {window.location.host.includes("localhost") ? (
            <img
              src={"https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/React.svg/1200px-React.svg.png"}
              alt="Main Logo"
              style={{ maxWidth: "100%", padding: "10px" }}
            ></img>
          ) : (
            <img src={mainLogo} alt="Main Logo" style={{ maxWidth: "100%", padding: "5px" }}></img>
          )}
        </Link>
      </div>
      <Menu theme="dark" mode="inline">
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
          <Tooltip placement="right" title={t("menu.dashboard")}>
            <div>
              <Link style={{ color: "#fff" }} to="/dashboard">
                {t("menu.dashboard")}
              </Link>
            </div>
          </Tooltip>
        </Menu.Item>
        {/* <SubMenu title={t("menu.agenda")} icon={<ProjectOutlined />}>
          <Menu.Item key="tasks" icon={<CheckSquareOutlined />}>
            <Link to="/tasks">{t("menu.tasks")}</Link>
          </Menu.Item>
          <Menu.Item key="goals" icon={<FundOutlined />}>
            <Link to="/goals">{t("menu.goals")}</Link>
          </Menu.Item>
        </SubMenu> */}
        <SubMenu title={t("menu.operations")} icon={<SwapOutlined />} key="operations">
          <Menu.Item key="buildings" icon={<HomeOutlined style={{ color: "#aaa" }} />}>
            <Link to="/buildings" style={{ color: "#fff" }}>
              {t("menu.buildings")}
            </Link>
          </Menu.Item>
          <Menu.Item key="buildingUnits" icon={<ShopOutlined style={{ color: "#aaa" }} />}>
            <Link to="/building-units" style={{ color: "#fff" }}>
              {t("menu.buildingUnits")}
            </Link>
          </Menu.Item>
          <Menu.Item key="buildingUnitSetupChanges" icon={<FileOutlined style={{ color: "#aaa" }} />}>
            <Link to="/building-unit-setup-changes" style={{ color: "#fff" }}>
              {t("breadcrumbs.buildingUnitSetupChanges")}
            </Link>
          </Menu.Item>
          {/*<Menu.Item key="offers" icon={<FileOutlined style={{ color: "#aaa" }} />}>*/}
          {/*  <Link to="/offers" style={{ color: "#fff" }}>*/}
          {/*    {t("menu.offers")}*/}
          {/*  </Link>*/}
          {/*</Menu.Item>*/}
          <Menu.Item key="partners" icon={<TeamOutlined style={{ color: "#aaa" }} />}>
            <Link to="/partners" style={{ color: "#fff" }}>
              {t("menu.partners")}
            </Link>
          </Menu.Item>
          <Menu.Item key="clients" icon={<BankOutlined style={{ color: "#aaa" }} />}>
            <Link to="/clients" style={{ color: "#fff", zIndex: 99, position: "relative" }}>
              {t("menu.clients")}
            </Link>
            <Link to="/clients?new" style={{ color: "#fff", marginLeft: "10px", content: "none" }}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill="rgb(255, 255, 255)" width="20" height="20" style={{marginBottom: "-5px"}} viewBox="0 0 50 50">
                <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z"></path>
              </svg>
            </Link>
          </Menu.Item>
          <Menu.Item key="contacts" icon={<UserOutlined style={{ color: "#aaa" }} />}>
            <Link to="/contacts" style={{ color: "#fff" }}>
              {t("menu.contacts")}
            </Link>
          </Menu.Item>
          <Menu.Item key="cleanings" icon={<ClearOutlined style={{ color: "#aaa" }} />}>
            <Link to="/cleanings" style={{ color: "#fff" }}>
              {t("menu.cleanings")}
            </Link>
          </Menu.Item>
          <Menu.Item key="claims" icon={<ExclamationCircleOutlined style={{ color: "#aaa" }} />}>
            <Link to="/claims" style={{ color: "#fff", zIndex: 99, position: "relative" }}>
              {t("menu.claims")}
            </Link>
            <Link to="/claims?new" style={{ color: "#fff", marginLeft: "10px", content: "none" }}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill="rgb(255, 255, 255)" width="20" height="20" style={{marginBottom: "-5px"}} viewBox="0 0 50 50">
                <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z"></path>
              </svg>
            </Link>
          </Menu.Item>
          <Menu.Item key="emails" icon={<MailOutlined style={{ color: "#aaa" }} />}>
            <Link to="/emails" style={{ color: "#fff" }}>
              {t("breadcrumbs.emails")}
            </Link>
          </Menu.Item>
        </SubMenu>
        {/* <SubMenu title={t("menu.feedback")} icon={<LikeOutlined />}>
          <Menu.Item key="ratings" icon={<StarOutlined />}>
            <Link to="/ratings">{t("menu.ratings")}</Link>
          </Menu.Item>
        </SubMenu> */}
        {/* <SubMenu title={t("menu.leads")} icon={<CheckSquareOutlined />}>
          <Menu.Item key="clientLeads" icon={<AppstoreAddOutlined />}>
            <Link to="/client-leads">{t("menu.clientLeads")}</Link>
          </Menu.Item>
          <Menu.Item key="partnerLeads" icon={<UserAddOutlined />}>
            <Link to="/partner-leads">{t("menu.partnerLeads")}</Link>
          </Menu.Item>
        </SubMenu> */}
        <SubMenu title={t("menu.finance")} icon={<EuroCircleOutlined />} key="finance">
          <Menu.Item key="invoices" icon={<FileDoneOutlined style={{ color: "#aaa" }} />}>
            <Link to="/invoices" style={{ color: "#fff" }}>
              {t("menu.invoices")}
            </Link>
          </Menu.Item>

          {/* <Menu.Item key="payments" icon={<CreditCardOutlined />}>
            <Link to="/payments">{t("menu.payments")}</Link>
          </Menu.Item> */}

          {/* Check if the user has a right role */}
          {(role?.name === "manager" || role?.name === "admin") && (
            <Menu.Item key="accounts" icon={<DollarOutlined style={{ color: "#aaa" }} />}>
              <Link to="/accounts" style={{ color: "#fff" }}>
                {t("finance.accounts.accounts")}
              </Link>
            </Menu.Item>
          )}

          <Menu.Item key="unlinked-transactions" icon={<FileExcelOutlined style={{ color: "#aaa" }} />}>
            <Link to="/unlinked-transactions" style={{ color: "#fff" }}>
              <span
                style={{
                  wordWrap: "break-word",
                }}
              >
                {t("finance.transactions.unassignedTransactions")}
              </span>
            </Link>
          </Menu.Item>

          <Menu.Item key="payments-for-approval" icon={<TransactionOutlined style={{ color: "#aaa" }} />}>
            <Link to="/payments-for-approval" style={{ color: "#fff" }}>
              <span
                style={{
                  wordWrap: "break-word",
                }}
              >
                {t("finance.transactions.paymentsForApproval")}
              </span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="expansions" icon={<FullscreenOutlined />}>
          <Tooltip placement="right" title={t("expansion.expansion")}>
            <div>
              <Link style={{ color: "#fff" }} to="/countries">
                {t("expansion.expansion")}
              </Link>
            </div>
          </Tooltip>
        </Menu.Item>
        <SubMenu title={t("menu.reports")} icon={<PieChartOutlined />} key="reports">
          <Menu.Item key="cityReports" icon={<BarChartOutlined style={{ color: "#aaa" }} />}>
            <Link to="/city-reports" style={{ color: "#fff" }}>
              {t("menu.cityReports")}
            </Link>
          </Menu.Item>
          <Menu.Item key="countryReports" icon={<RadarChartOutlined style={{ color: "#aaa" }} />}>
            <Link to="/country-reports" style={{ color: "#fff" }}>
              {t("menu.countryReports")}
            </Link>
          </Menu.Item>
          {role?.name === "admin" && (
            <Menu.Item key="dac7Reports" icon={<BarChartOutlined style={{ color: "#aaa" }} />}>
              <Link to="/dac7-reports" style={{ color: "#fff" }}>
                {t("menu.dac7Reports")}
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
        {/* Check if the user has a right role */}
        {role?.name === "admin" && (
          <Menu.Item key="employees" icon={<TeamOutlined />}>
            <Tooltip placement="right" title={t("menu.employees")}>
              <div>
                <Link style={{ color: "#fff" }} to="/employees">
                  {t("menu.employees")}
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        )}

        {role?.name === "admin" && (
          <Menu.Item key="adminTools" icon={<ToolOutlined />}>
            <Tooltip placement="right" title={t("menu.adminTools")}>
              <div>
                <Link style={{ color: "#fff" }} to="/commands">
                  {t("menu.adminTools")}s
                </Link>
              </div>
            </Tooltip>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};

export default SideNav;
