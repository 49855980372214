import { Button, Form, Modal, notification, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { coreApi } from "../../api/calls";
import InitialBuildingUnitCleaningGenerator from "./InitialBuildingUnitCleaningGenerator";

const InitialInvoiceAddForm = ({ buildingUnitIds, displayed, refreshScheduleList }) => {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buildingUnits, setBuildingUnits] = useState([]);

  const [form] = Form.useForm();

  const fetchInitialInvoiceData = async () => {
    setLoading(true);
    try {
      const response = await coreApi.get("/buildings/units/initial-invoice-data", {
        params: { building_units: buildingUnitIds },
      });
      setBuildingUnits(response.data);
    } catch (error) {
      notification.error({ message: error?.response?.data?.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalOpen && buildingUnitIds && buildingUnitIds.length > 0) {
      fetchInitialInvoiceData();
    }
  }, [buildingUnitIds, isModalOpen]);

  return displayed ? (
    <React.Fragment>
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t("finance.invoices.addInitialInvoice")}
      </Button>
      <Modal
        width={700}
        open={isModalOpen}
        title={t("finance.invoices.addInitialInvoice")}
        okText={t("common.create")}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{ loading: submitting }}
        onCancel={() => {
          setModalOpen(false);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          onFinish={(values) => {
            setSubmitting(true);

            let payload = {
              building_unit_id: values.building_unit_id,
              create_rules: values.create_rules,
              partner_id: values.partner_id,
              service_ratio: values.service_ratio,
              date_cleanings_start: values.date_cleanings_start.format("YYYY-MM-DD"),
              cleaning_days: values.days,
              biweekly_type: values.biweeklyType,
              cleanings: values.cleanings.map((cleaning) => {
                return {
                  date_expected: cleaning.date_expected.format("YYYY-MM-DD"),
                  price: cleaning.price,
                  promo: cleaning.promo,
                };
              }),
            };

            coreApi
              .post("/cleanings/recurring/during-month", {
                building_units: [payload],
              })
              .then((response) => {
                notification.success({
                  message: response.data.message,
                });
                form.resetFields();
                setModalOpen(false);
                refreshScheduleList();
              })
              .catch((error) => {
                notification.error({
                  message: error?.response?.data?.message,
                });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          initialValues={{ building_units: buildingUnits }}
          layout={"vertical"}
        >
          {loading ? (
            <Skeleton />
          ) : (
            buildingUnits.map((buildingUnit) => {
              return (
                <InitialBuildingUnitCleaningGenerator key={buildingUnit?.id} form={form} buildingUnit={buildingUnit} />
              );
            })
          )}
        </Form>
      </Modal>
    </React.Fragment>
  ) : (
    ""
  );
};

export default InitialInvoiceAddForm;
