import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchManagers } from "../api/managerApi";

const ManagerSelect = ({
  clearable = false,
  label,
  validationMessage,
  initialValue,
  required,
  readOnly,
  showSearch = false,
  sendOnchange = null,
  placeholder = null,
  activeOnly = false,
  mode="single"
}) => {
  const { t } = useTranslation();

  const [managers, setManagers] = useState([]);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    const loadManagers = async () => {
      const sortedManagers = await fetchManagers(activeOnly);
      setManagers(sortedManagers);
    };

    loadManagers();
  }, [activeOnly]);


  useEffect(() => {
    let foundEmployee = null;
    if (managers.length > 0){
      foundEmployee = managers.find(employee => employee?.user?.id === initialValue);
    }

    setSelectedValue(foundEmployee || null);

  }, [managers, initialValue]);

  useEffect(() => {
    setSelectedValue(initialValue);
  }, [initialValue]);

  return (
    <Form.Item
      name="manager_id"
      label={label}
      rules={[
        {
          required: required,
          message: validationMessage,
        },
      ]}
      initialValue={selectedValue}
      style={{minWidth: '240px'}}
    >
      <Select
        mode={mode}
        onChange={(value, option) => {
          setSelectedValue(value);
          if (sendOnchange) sendOnchange(value, option);
        }}
        allowClear={clearable}
        disabled={readOnly}
        bordered={!readOnly}
        showSearch={showSearch}
        placeholder={placeholder || t("managers.selectManager")}
        filterOption={(input, option) =>
          typeof option.children === "string" &&
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {managers.map((manager) => {
          return (
            <Select.Option key={manager.id} value={manager.id} data-user-id={manager?.user?.id || null}>
              {manager?.user?.deleted_at === null ? (
                `${manager.first_name} ${manager.last_name}`
              ) : (
                <s>{`${manager.first_name} ${manager.last_name}`}</s>
              )}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default ManagerSelect;
