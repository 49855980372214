import { Col, Row, Card } from "antd";
import React from "react";
import Dac7YearlyExport from "../components/Dac7/Dac7YearlyExport";

const Dac7Reports = () => (
  <Row gutter={[16, 16]}>
    <Col span={12}>
      <Card title="Dac7 Export">
        <Dac7YearlyExport />
      </Card>
    </Col>
  </Row>
);

export default Dac7Reports;