import React, { useState, useEffect } from "react";
import { Form, Select, DatePicker, Button, Input, Row, Col, notification } from "antd";
import BuildingUnitSelect from './../BuildingUnitSelect';
import ManagerSelect from '../../ManagerSelect';
import PartnerSelect from './../../Partners/PartnerSelect';
import RruleDaySelect from '../../Scheduler/RruleDaySelect';
import { coreApi } from "../../../api/calls";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { TextArea } = Input;
const formItemStyle = { marginBottom: 0 };


const MassEditForm = ({ selectedUnits, onSave, onCancel }) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState([]);

  useEffect(() => {
    fetchActions();
  }, []);

  const fetchActions = async () => {
    try {
      const response = await coreApi.get("/building-unit-setup-changes/actions/list-actions");
      setActions(response.data);
    } catch (error) {
      notification.error({ message: t("buildingSetupChanges.messages.failedLoadData") });
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const payload = {
      ...values,
      building_unit_setup_changes_ids: selectedUnits.map((unit) => unit.id)
    };

    coreApi
      .put("/building-unit-setup-changes/actions/mass-update", payload)
      .then((response) => {
        notification.success({ message: response.data?.message || t("common.saveSuccess") });
        onSave();
        form.resetFields();
        form.setFieldsValue({ substitute_rrule: null });
      })
      .catch((error) => {
        notification.error({ message: t("common.saveFailed") + (error.response?.data?.message || "") });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item name="building_unit_ids" style={formItemStyle}>
            <BuildingUnitSelect
              label={t("buildingSetupChanges.buildingUnit")}
              name="building_unit_ids"
              mode="multiple"
              readOnly={true}
              selectAllOnLoad={true}
              defaultBuildingUnitList={selectedUnits.map(unit => unit.building_unit)}
              form={form}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="manager_id" style={formItemStyle}>
            <ManagerSelect
              label={t("buildingSetupChanges.manager")}
              showSearch={true}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("buildingSetupChanges.action")} name="action_id">
            <Select placeholder={t("buildingSetupChanges.selectAction")} allowClear>
              {actions.map(action => {
                const translation = action.translations.find(t => t.language_ietf === i18n.language) || action.translations[0];
                return (
                  <Option key={action.id} value={action.id}>
                    {translation?.tag_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("buildingSetupChanges.actionWeek")} name="action_period">
            <DatePicker.RangePicker placeholder={[t("buildingSetupChanges.selectStartDate"), t("buildingSetupChanges.selectEndDate")]} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("common.partner")} name="partner_id">
            <PartnerSelect
              name="partner_id"
              allowClear
              onChange={(value) => form.setFieldsValue({ partner_id: value?.id })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("common.substitutePartner")} name="substitute_partner_id">
            <PartnerSelect
              name="substitute_partner_id"
              allowClear
              onChange={(value) => form.setFieldsValue({ substitute_partner_id: value?.id })}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("common.day")} name="substitute_rrule">
            <RruleDaySelect
              size="small"
              className="mb-0"
              rrule={null}
              postBlur={(rruleString) => form.setFieldsValue({ substitute_rrule: rruleString })}
              showLabel={false}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="notes">
            <TextArea rows={4} placeholder={t("common.notes")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit"  loading={loading} disabled={loading}>
              {t("common.save")}
            </Button>
            <Button type="ghost" onClick={onCancel} style={{ marginLeft: 8 }}>
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MassEditForm;
