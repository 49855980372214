import { UploadOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, notification, Row, Upload, Table, Spin, Tooltip } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { coreApi } from "../../api/calls";

const requiredColumns = ["Building ref. number", "New price"];

const ImportOrdersPrice = ({ isVisible = false, onClose }) => {
  const [uploadForm] = Form.useForm();
  const [isOpen, setIsOpen] = useState(isVisible);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [fileData, setFileData] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileHeaders, setFileHeaders] = useState([]);


  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  const parseTSV = (content) => {
    const lines = content.trim().split("\n");
    const headers = lines[0].split("\t").map(header => header.trim());
    setFileHeaders(headers);

    // Validate headers
    const missingColumns = requiredColumns.filter((col) => !headers.includes(col));
    if (missingColumns.length > 0) {
      setValidationError(`Missing columns: ${missingColumns.join(", ")}`);
      return;
    } else {
      setValidationError("");
    }

    // Parse the data rows
    const data = lines.slice(1).filter(line => line.trim() !== "").map((line, index) => {
      const values = line.split("\t");
      let row = { key: index };
      headers.forEach((header, idx) => {
        row[header] = values[idx] || "";
      });
      return row;
    });
    setFileData(data);
  };

  const handleUpload = (file) => {
    setLoading(true);
    setUploadedFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      parseTSV(content);
      setLoading(false);
    };
    reader.readAsText(file);
  };

  const handleSubmit = async () => {
    if (!uploadedFile) {
      notification.error({ message: "Please upload a valid TSV file." });
      return;
    }

    setSubmitting(true);
    const formData = new FormData();
    formData.append("file", uploadedFile);

    try {
      const response = await coreApi.post("/orders/import-orders-price-from-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      notification.success({ message: response?.data?.message || "File uploaded and data imported successfully!" });
      handleCancel();
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || "File upload failed. Please try again.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    onClose();
  };

  const columns = useMemo(() => [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    ...fileHeaders.map((header) => ({
      title: header,
      dataIndex: header,
      key: header,
    })),
  ], [fileHeaders]);


  return (
    <Modal
      title="Import Orders"
      open={isOpen}
      onOk={handleSubmit}
      okButtonProps={{ disabled: !filePath || validationError, loading: submitting }}
      onCancel={handleCancel}
      confirmLoading={submitting}
      width={800}
    >
      <Form layout="vertical" form={uploadForm}>
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Form.Item
              label={
                <>
                  <span>Upload .tsv </span>
                  <span> (Required columns: {requiredColumns.map((col) => `"${col}"`).join(", ")})</span>
                </>
              }
              name="orders_tsv"
              valuePropName="file"
              rules={[
                {
                  required: true,
                  message: "Please upload a valid TSV file.",
                },
              ]}
            >
              <Upload
                maxCount={1}
                listType="text"
                customRequest={({ file }) => {
                  handleUpload(file);
                }}
                onChange={({ fileList }) => {
                  setFilePath(fileList.length > 0 ? fileList[0].name : "");
                }}
              >
                {!filePath && <Button icon={<UploadOutlined />}>Upload</Button>}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        {loading ? (
          <Spin size="large" />
        ) : (
          fileData.length > 0 && (
            <Row gutter={[16, 24]}>
              <Col span={24}>
                <Table
                  dataSource={fileData}
                  columns={columns}
                  pagination={false}
                  rowKey="key"
                  scroll={{ y: 240 }}
                />
              </Col>
            </Row>
          )
        )}
        {validationError && (
          <Row gutter={[16, 24]}>
            <Col span={24}>
              <div style={{ color: "red" }}>{validationError}</div>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default ImportOrdersPrice;
