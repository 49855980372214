import React, { useState, useEffect } from "react";
import { Modal, Upload, Button, Table, Form, notification, Spin, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { coreApi } from "../../api/calls";

const ImportCustomCsv = ({ isVisible = false, onClose }) => {
  const [isOpen, setIsOpen] = useState(isVisible);
  const [fileData, setFileData] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { Text } = Typography;

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  const parseCsv = (content) => {
    const lines = content.trim().split("\n");
    const headers = lines[0].split(",");
    const rows = lines.slice(1, 11).map((line, index) => {
      const values = line.split(",");
      const row = { key: index };
      headers.forEach((header, idx) => {
        row[header] = values[idx] || "";
      });
      return row;
    });
    setFileData(rows);
  };

  const sanitizeFileName = (name) => {
    return name
      .replace(/[^a-zA-Z0-9._-]/g, "_")
      .replace(/_{2,}/g, "_");
  };

  const handleUpload = (file) => {
    const sanitizedFileName = sanitizeFileName(file.name);
    const renamedFile = new File([file], sanitizedFileName, { type: file.type });
    setUploadedFile(renamedFile);

    const reader = new FileReader();
    reader.onload = (e) => {
      parseCsv(e.target.result);
      setLoading(false);
    };
    reader.readAsText(renamedFile);
    return false;
  };


  const handleSubmit = async () => {
    if (!uploadedFile) {
      notification.error({ message: "Please upload a CSV file." });
      return;
    }
    setSubmitting(true);
    const formData = new FormData();
    formData.append("file", uploadedFile);

    try {
      const response = await coreApi.post("/import/custom-csv", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      notification.success({ message: response?.data?.message || "File imported successfully!" });
      handleClose();
    } catch (error) {
      notification.error({
        message: error.response?.data?.message || "File import failed. Please try again.",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
    setUploadedFile(null);
    setFileData([]);
  };

  const columns = fileData.length
    ? Object.keys(fileData[0]).map((key) => ({
      title: key,
      dataIndex: key,
      key,
      ellipsis: true,
    }))
    : [];

  return (
    <Modal
      title="Import Custom CSV"
      open={isOpen}
      onOk={handleSubmit}
      onCancel={handleClose}
      okButtonProps={{ disabled: !uploadedFile, loading: submitting }}
      confirmLoading={submitting}
      width={800}
    >
      <Form layout="vertical">
        <Upload
          maxCount={1}
          accept=".csv"
          beforeUpload={handleUpload}
          onRemove={() => {
            setUploadedFile(null);
            setFileData([]);
          }}
        >
          <Button icon={<UploadOutlined />}>Upload CSV</Button>
        </Upload>
        {loading && <Spin size="large" />}
        {!loading && fileData.length > 0 && (
          <>
            <Table
              dataSource={fileData}
              columns={columns}
              pagination={false}
              scroll={{ y: 240, x: "max-content" }}
              style={{ marginTop: 16 }}
            />
            <Text style={{ display: "block", marginTop: 8 }}>
              Showing {fileData.length} rows
            </Text>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ImportCustomCsv;
