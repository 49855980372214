import { coreApi } from "./calls";

export const fetchManagers = async (activeOnly = false, defaultUser) => {
  const user = defaultUser || JSON.parse(localStorage.getItem("user"));
  const url = activeOnly ? "/employees/all-active" : "/employees/all";
  try {
    const response = await coreApi.get(url);

    if (!response || !response.data || !Array.isArray(response.data)) {
      console.error("Invalid managers data from API: " + url);
      return [];
    }

    const sortedManagers = response.data.sort((a, b) => {
      if (a.id === user.employee_id) return -1;
      if (b.id === user.employee_id) return 1;
      if (a.user?.deleted_at === null && b.user?.deleted_at !== null) return -1;
      if (a.user?.deleted_at !== null && b.user?.deleted_at === null) return 1;

      const firstNameComparison = a.first_name.localeCompare(b.first_name);
      if (firstNameComparison !== 0) return firstNameComparison;
      return a.last_name.localeCompare(b.last_name);
    });

    return sortedManagers;
  } catch (error) {
    console.error("Failed to fetch managers from url: " + url , error);
    return [];
  }
};
